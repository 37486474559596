import React, { useEffect, useState } from 'react';
import {
  useReactTable,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import ConfigData from './configurationdata.json';
import { Stack, Button } from '@mui/material';

interface Configuration {
  attributeName: string;
  type: string;
  value?: string;
  canAddField?: boolean;
  subRows?: Configuration[];
}

type Example = {
  name: string;
  value: string;
  type: string;
  attributeName: string;
  canAddField?: boolean;
  subRows?: Example[];
};

type RowType = {
  [key: string]: any;
  canAddField?: boolean;
};
type TableConfiguration = {
  configurations: Configuration[];
};

const columnHelper = createColumnHelper<Example>();

const BasicTable: React.FC<TableConfiguration> = ({ configurations }) => {
  // const [data, setData] = useState([...defaultData]);

  const defaultData = [...configurations, ...ConfigData];

  const defaultColumns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: ({ table }) => (
        <Stack justifyContent={'center'} direction={'row'} spacing={1}>
          {/* <button onClick={table.getToggleAllRowsExpandedHandler()}>
          {table.getIsAllRowsExpanded() ? '-' : '+'}
        </button> */}
          First Name
        </Stack>
      ),
      cell: ({ row, getValue }) => (
        <div
          style={{
            paddingLeft: `${row.depth * 1.5}rem`,
          }}
        >
          {row.getCanExpand() ? (
            <span className='attribute-name-container'>
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: 'pointer' },
                }}
              >
                {row.getIsExpanded() ? '-' : '+'}
              </button>
              {row.original.attributeName}
              <div>{getValue()}</div>{' '}
            </span>
          ) : (
            <>
              <span className='attribute-name-container'>
                <button>-</button>
                {row.original.attributeName}
              </span>
            </>
          )}{' '}
          {}
        </div>
      ),
    }),

    columnHelper.accessor('value', {
      id: 'Value',
    }),
    columnHelper.accessor('type', {
      id: 'Type',
    }),
  ];

  const [data, setData] = useState<any[]>([...defaultData]);

  const [columns, setColumns] = useState([...defaultColumns]);
  const [expanded, setExpanded] = useState({});
  const instance = useReactTable({
    columns,
    data,
    state: {
      expanded: expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  console.log(instance.getRowModel());

  const handleAddFieldClick = (rowId: string) => {
    setData((prevData) => {
      return prevData.map((row) => {
        const typedRow = row as RowType; // Cast row to RowType
        if (row.id === rowId && typedRow.canAddField) {
          const newSubRow = {
            attributeName: '',
            value: '',
            type: '',
          };
          row.subRows = row.subRows ? [...row.subRows, newSubRow] : [newSubRow];
        }
        return row;
      });
    });
  };

  useEffect(() => {
    setData((prevData) => [...prevData, ...configurations]);
  }, [configurations]);

  return (
    <div className='multi-level-table-show'>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          {instance.getHeaderGroups().map((headerGroup) => (
            <tr style={{ display: 'flex' }} key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {instance.getRowModel().rows.map((row) => (
            <tr key={row.id} className={`depth-${row.depth}`}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {cell.column.id === 'value' &&
                  (row as RowType).canAddField ? (
                    <Button onClick={() => handleAddFieldClick(row.id)}>
                      Add new Field
                    </Button>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BasicTable;
