import React, { useState } from 'react';
import './globalsetting.css';
import EditIcon from '@mui/icons-material/Edit';
import TableData from './configurationdata.json';
import BasicTable from './TableShow';

interface Configuration {
  attributeName: string;
  type: string;
}

interface ConfigurationFormProps {
  onSubmit: (configuration: Configuration) => void;
}

const GlobalSetting: React.FC = () => {
  const [configurationName, setConfigurationName] = useState('');
  const [configurationType, setConfigurationType] = useState('string');
  const [configurations, setConfigurations] = useState<Configuration[]>([]);

  const handleConfigurationNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfigurationName(e.target.value);
  };

  const handleConfigurationTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setConfigurationType(e.target.value);
  };

  const handleAddConfiguration = () => {
    const newConfiguration: Configuration = {
      attributeName: configurationName,
      type: configurationType,
    };

    setConfigurations([...configurations, newConfiguration]);
    setConfigurationName('');
    setConfigurationType('string');
  };

  return (
    <div>
      <section>
        <h2>Add Configuration</h2>
        <div>
          <label htmlFor='configurationName'>Configuration Name:</label>
          <input
            type='text'
            id='configurationName'
            placeholder='Enter text'
            value={configurationName}
            onChange={handleConfigurationNameChange}
          />
          <span>Type:</span>
          <select
            value={configurationType}
            onChange={handleConfigurationTypeChange}
          >
            <option value='string'>String</option>
            <option value='number'>Number</option>
            <option value='boolean'>Boolean</option>
            <option value='map'>Map</option>
            <option value='list'>List</option>
          </select>
        </div>
        <button onClick={handleAddConfiguration}>Add Configuration</button>
      </section>

      <section className='attribute-table-section'>
        <h2>Attributes</h2>
        {/* <TableWithCollapsibleRows data={TableData} /> */}
        {/* <NestedTable /> */}
        <BasicTable configurations={configurations} />
      </section>
    </div>
  );
};

export default GlobalSetting;
