interface UserData {
  id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_superuser: boolean;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  // Add more properties as needed
}

interface CheckLoginResponse {
  loggedIn: boolean;
  userData?: UserData;
}

export const loginAdmin = async (
  username: string,
  password: string
): Promise<boolean> => {
  const apiUrl = process.env.REACT_APP_BASE_URL as string + 'admin-login';
  const requestBody = {
    username: username,
    password: password,
  };

  console.log(requestBody, apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    // const response = await fetch(apiUrl, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': 'http://localhost:3000',
    //   },
    //   body: JSON.stringify(requestBody),
    // });

    console.log(response);

    if (response.ok) {
      const responseData = await response.json();

      const { refresh, access } = responseData;

      localStorage.setItem('refreshToken', refresh);
      localStorage.setItem('accessToken', access);

      return true;
    } else {
      const errorResponse = await response.json();
      console.error('Login failed:', errorResponse.detail);
      alert(errorResponse.detail);
      return false;
    }
  } catch (error) {
    console.error('Error during login:', error);
    alert(error);
    return false;
  }
};

export const getAboutMe = async (): Promise<any> => {
  const accessToken = localStorage.getItem("accessToken");
  return new Promise((resolve, reject) => {
    const apiUrl = process.env.REACT_APP_BASE_URL as string + 'admin-me';
    fetch(
      apiUrl,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      }
    )
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          resolve(data);
        } else {
          reject(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(false);
      });
  });
};

export const checkIsAdminLoggedIn = async (): Promise<CheckLoginResponse> => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const apiUrl = process.env.REACT_APP_BASE_URL as string + 'admin-me';

  if (!accessToken || !refreshToken) {
    return { loggedIn: false };
  }
  try {
    const response = await fetch(
      apiUrl,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      // Optionally, you can store the user data in state or localStorage
      return { loggedIn: true, userData: data };
    } else {
      console.error('Error fetching user details:', response.statusText);
      return { loggedIn: false };
    }
  } catch (error) {
    console.error('Error during API call:', error);
    return { loggedIn: false };
  }
};
