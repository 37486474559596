import React, { useEffect, useState } from 'react';
import './adminpage.css';
import TradeImg from '../../assets/images/trade.jpg';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link } from 'react-router-dom';
import MonitorIcon from '@mui/icons-material/Monitor';
import { deleteStrategyAPICall } from '../../API/AdminPageAPI';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '../../Utils/Constants';
import { BorderBottom } from '@mui/icons-material';
import Loader from '../Dialogs/Loader';
interface AdminTableDataItem {

  
  id: string;
  is_live: boolean;
  strategy_name: string;
  minimum_investment_capital: number;
  capital_deployed: number;
  cagr_pct: number;
  risk: string;
  strategy_id: number;
  strategy_image: string;
}
interface AdminPageContentProps {
  AdminTableData: AdminTableDataItem[];
  onUpdateIsLive: (index: number, newValue: boolean) => void;
  onDeleteStrategy: (strategyId: string) => void;
}

const getCAGRColor = (cagr: number) => {
  if (cagr == 0) {
    return '';
  } else {
    return cagr > 0 ? '#2ACD1C' : '#F82929';
  }
};

const formatNumber = (value: number) => {
  if (value >= 10000000) {
    return `${(value / 10000000).toFixed(2)} Cr`;
  } else if (value >= 100000) {
    return `${(value / 100000).toFixed(2)} L`;
  } else {
    const formattedValue = value?.toString();
    const decimalIndex = formattedValue.indexOf('.');
    if (decimalIndex !== -1) {
      const fractionalPart = formattedValue.slice(decimalIndex + 1);
      if (/^0+$/.test(fractionalPart)) {
        return formattedValue.slice(0, decimalIndex); // Remove trailing zeros after decimal
      }
    }
    return formattedValue;
  }
};

const AdminPageContent: React.FC<AdminPageContentProps> = ({
  AdminTableData,
  onUpdateIsLive,
  onDeleteStrategy,
}) => {
  //

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [strategyIdToDelete, setStrategyIdToDelete] = useState<string>('');
  const [strategyNameToDelete, setStrategyNameToDelete] = useState<string>('');
  const [isLoading, setIsLoading] = React.useState(true);

  const handleDeleteClick = (strategyId: string, strategyName: string) => {
    setStrategyIdToDelete(strategyId);
    setStrategyNameToDelete(strategyName);
    console.log(strategyId, strategyName);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await onDeleteStrategy(strategyIdToDelete);
      setShowConfirmation(false);

      alert('Strategy deleted successfully');
    } catch (error) {
      console.error('Error deleting strategy:', error);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setStrategyIdToDelete('');
  };

  useEffect(() => {
    if(AdminTableData.length > 0){
      setIsLoading(false);
    }else{
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, []);


  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
    <div style={{ ...styles.fullPageContainer }}>
      <div style={styles.tableContainer}>
        <table style={{ ...styles.table }} className=''>
          <thead style={styles.tableHeading}>
            <tr>
              <th style={styles.tabelNavItems}>
                <input
                  type='radio'
                  id='radioButton'
                  name='radioGroup'
                  value='option1'
                />
              </th>
              <th style={styles.tabelNavItems}>ID</th>
              <th style={styles.tabelNavItems}></th>
              <th style={styles.tabelNavItems}>IMAGE</th>
              <th style={styles.tabelNavItems}>STRATEGY NAME</th>
              <th style={styles.tabelNavItems}>MIN. CAPITAL(₹)</th>
              {/* <th style={styles.tabelNavItems}>CAPITAL DEPLOYED(₹)</th> */}
              <th style={styles.tabelNavItems}>CAGR(%)</th>
              <th style={styles.tabelNavItems}>RISK</th>
              {/* <th style={styles.tabelNavItems}></th> */}
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody style={styles.tbody}>
            {AdminTableData.map((data, index) => {
              return (
                <tr style={styles.tableRow} key={index}>
                  <td style={{ display: 'none' }}></td>
                  <td
                    style={{
                      display: 'flex',
                      // alignItems: 'center',
                      // justifyContent: 'center',
                      marginTop: '50%',
                      ...styles.tableData,
                    }}
                  >
                    <input
                      type='radio'
                      name='radioGroup'
                      id='radioButton'
                      value='option1'
                    />
                  </td>
                  <td style={{}}>0{index + 1}</td>
                  <td style={{ ...styles.tableData }}>
                    <input
                      type='checkbox'
                      id={data.id}
                      className='checkbox'
                      checked={data.is_live}
                      style={
                        {
                          // position: 'absolute',
                          // top: '50%',
                        }
                      }
                    />
                    <label
                      htmlFor={data.id}
                      className='toggle'
                      onClick={() => onUpdateIsLive(index, !data.is_live)}
                    >
                      <p
                        style={{
                          marginLeft: !data.is_live ? '15px' : '0',
                          marginRight: data.is_live ? '10px' : '0',
                          color: '#fff',
                          fontSize: '8.5px',
                        }}
                      >
                        {data.is_live ? 'Live' : 'Go Live'}
                      </p>
                    </label>
                  </td>

                  <td style={{ ...styles.tableData }}>
                    <img
                      src={data.strategy_image ? data.strategy_image : TradeImg}
                    />
                  </td>
                  <td
                    style={{
                      ...styles.tableData,
                    }}
                  >
                    {data.strategy_name.length > 9
                      ? data.strategy_name.substring(0, 9) + '...'
                      : data.strategy_name}
                  </td>

                  <td style={{ ...styles.tableData }}>
                    {formatNumber(data.minimum_investment_capital)}
                  </td>
                  {/* <td style={{ ...styles.tableData }}>
                    {formatNumber(data.CapitalDeployed)}
                    {data.CapitalDeployed}
                  </td> */}
                  <td
                    style={{
                      color: getCAGRColor(data.cagr_pct),
                      ...styles.tableData,
                    }}
                  >
                    {data.cagr_pct > 0 ? '+' : ''}
                    {data.cagr_pct}
                  </td>
                  <td
                    // className='admin-risk-data'
                    style={{
                      color:
                        data.risk === 'High Risk'
                          ? '#F82929'
                          : data.risk === 'Medium Risk'
                          ? '#E7AF1D'
                          : '#2ACD1C',
                      fontSize: '14px',
                      ...styles.tableData,
                    }}
                  >
                    <span
                      style={{
                        border: 'solid rgba(178, 178, 178, 0.7)',
                        height: '10px',
                        padding: '2.5px 2px',
                        borderRadius: '5px',
                      }}
                    >
                      {data.risk === 'Medium Risk' ? 'Mid Risk' : data.risk}
                    </span>
                  </td>
                  <td style={{ ...styles.tableData }}>
                    {/* <article className='admin-btn-collection'>
              </article> */}

                    <Link to={`/kill-switch/${data.strategy_id}`}>
                      <button
                        style={{
                          cursor: 'pointer',
                          fontSize: Theme.fontSizes.h5,
                        }}
                      >
                        <MonitorIcon className='admin-page-icon' />
                        Monitor
                      </button>
                    </Link>
                  </td>
                  <td style={{}}>
                    <Link to={`/edit/${data.strategy_id}`}>
                      <button
                        style={{
                          cursor: 'pointer',
                          fontSize: Theme.fontSizes.h5,
                        }}
                      >
                        <SettingsOutlinedIcon className='admin-page-icon' />
                        Strategy
                      </button>
                    </Link>
                  </td>

                  <td style={{ cursor: 'pointer' }}>
                    <DeleteForeverIcon
                      onClick={() =>
                        handleDeleteClick(
                          `${data.strategy_id}`,
                          `${data.strategy_name}`
                        )
                      }
                      style={{}}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
          {showConfirmation && (
            <div className='delete-strategy-popup-card'>
              <p>
                Are you sure you want to delete {strategyNameToDelete} strategy?
              </p>
              <div className='btn-container flex flex-center'>
                <button
                  className='filled-btn flex'
                  onClick={handleConfirmDelete}
                >
                  <DoneIcon />
                  Confirm
                </button>
                <button
                  className='outlined-btn flex'
                  onClick={handleCancelDelete}
                >
                  <CloseIcon />
                  Cancel
                </button>
              </div>
            </div>
          )}
        </table>
      </div>
    </div>
      )}
    </>
  );
};

const styles = {
  fullPageContainer: {
    padding: Theme.gapSmall,
    minWidth: '100%',
    height: '100%',
  },
  tableContainer: {
    minWidth: '100%',
    overflowX: 'auto' as const,
    height: '100%',
  },
  table: {
    minWidth: '100%',
    borderRadius: '3px',
    borderSpacing: '0px',
    borderCollapse: 'collapse' as const,
    padding: '0',
    marginTop: '0px',
    marginBottom: '94px',
    whiteSpace: 'nowrap',
    backgroundColor: '#FFFFFF,',
  },
  tbody: {
    borderBottom: `solid ${Theme.colors.whitishGrey} `,
    // border: 'none',
  },
  tableHeading: {
    fontFamily: 'poppins',
    background: '#f3f6ff',
    height: '51px',
    borderRadius: '3px',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  tableNav: {
    color: '#000',
    fontSize: '14px',
    letterSpacing: '0.84px',
    width: '100%',
  },
  tabelNavItems: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    letterSpacing: '0.06em',
    textAlign: 'center' as const,
    padding: '0 5px',
  },
  tableRow: {
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.7)',
    // lineHeight: '56px',
    fontSize: '16px',
    borderTop: '1px rgba(178, 178, 178, 0.7) solid',
    height: '65px',
    position: 'relative' as const,
  },
  tableData: {
    width: 'auto',
    // height: '100%',
    textAlign: 'center' as const,
  },
};

export default AdminPageContent;
