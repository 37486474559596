export interface UploadResponse {
  object_url: string;
  response_message: string;
}

export async function uploadFileAPI(
  strategyId: string,
  file: File,
  binaryData: any
): Promise<UploadResponse> {
  try {
    if (file.type !== 'application/pdf') {
      throw new Error('File must be a PDF');
    }
    const formData = new FormData();
    formData.append('file', file);
    let fileName = file.name;

    fileName = fileName.replace(/\s/g, '').replace(/[^a-zA-Z0-9_.-]/g, '');

    const uniqueId = Date.now().toString();
    const fileFormat = file.name.split('.').pop();
    const uniqueFileName = `${uniqueId}_${fileName}`;
    console.log(fileFormat, uniqueFileName);

    const response = await fetch(
      `${process.env.REACT_APP_FILE_UPLOAD}/${strategyId}/${uniqueFileName}`,
      {
        method: 'PUT',
        headers: {
          // 'content-type': `image/${fileFormat}`,
          'content-type': `application/pdf`,
        },
        body: binaryData,
      }
    );
    console.log(response);
    // if (!response.ok) {
    //   throw new Error('Failed to upload file');
    // }

    const responseData: UploadResponse = await response.json();
    console.log(responseData);

    return responseData;
  } catch (error: any) {
    throw new Error('Failed to upload file', error);
  }
}

export async function uploadImageAPI(
  strategyId: string,
  file: File,
  binaryData: any
): Promise<UploadResponse> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    let fileName = file.name;

    fileName = fileName.replace(/\s/g, '').replace(/[^a-zA-Z0-9_.-]/g, '');

    const uniqueId = Date.now().toString();
    const fileFormat = file.name.split('.').pop();
    const uniqueFileName = `${uniqueId}_${fileName}`;
    console.log(fileFormat, uniqueFileName);

    const response = await fetch(
      `${process.env.REACT_APP_FILE_UPLOAD}/${strategyId}/${uniqueFileName}`,
      {
        method: 'PUT',
        headers: {
          'content-type': `image/${fileFormat}`,
        },
        body: binaryData,
      }
    );
    console.log(response);
    // if (!response.ok) {
    //   throw new Error('Failed to upload file');
    // }

    const responseData: UploadResponse = await response.json();
    console.log(responseData);

    return responseData;
  } catch (error: any) {
    throw new Error('Failed to upload file', error);
  }
}
