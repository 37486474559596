import React, { useCallback, useEffect, useRef, useState } from "react";
import AdminStrategySettingsData from "./AdminStrategySettingsData";

interface AdminStrategySettingsProps {
    strategyId?: number;
    metaCallBack: (data: string) => void;
}

const AdminStrategySettings: React.FC<AdminStrategySettingsProps> = ({
    strategyId,
    metaCallBack
}) => {
    const [metaData, setMetaData] = useState("");
    const [width, setWidth] = useState(0);
    const [widthOfValue, setWidthOfValue] = useState(0);
    const [updatedMetaData, setUpdatedMetaData] = useState("");


    const [configName, setConfigName] = useState("");
    const [configDataType, setConfigDataType] = useState("string");

    const componentref = useRef<HTMLDivElement>(null);
    const valuecomponentref = useRef<HTMLDivElement>(null);

    const [childFunction, setChildFunction] = useState<(() => void) | null>(null);

    const handleChildFuncCallback = useCallback((fn: () => void) => {
      setChildFunction(() => fn);
    }, []);
  
    const handleClick = () => {
      if (childFunction) {
        childFunction();
      }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === componentref.current) {
                    setWidth(entry.contentRect.width);
                }
            }
        });

        if (componentref.current) {
            resizeObserver.observe(componentref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === valuecomponentref.current) {
                    setWidthOfValue(entry.contentRect.width);
                }
            }
        });

        if (valuecomponentref.current) {
            resizeObserver.observe(valuecomponentref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);



    function TickIcon() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="9"
                fill="none"
                viewBox="0 0 13 9"
            >
                <path
                    fill="#fff"
                    d="M4.136 7.12L1.034 4.298 0 5.239 4.136 9 13 .94 11.966 0l-7.83 7.12z"
                ></path>
            </svg>
        );
    }

    function TrashIcon() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="18"
                fill="none"
                viewBox="0 0 14 18"
                className="pointer"
            >
                <path
                    fill="#000"
                    fillOpacity="0.7"
                    d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zm2.46-7.12l1.41-1.41L7 9.59l2.12-2.12 1.41 1.41L8.41 11l2.12 2.12-1.41 1.41L7 12.41l-2.12 2.12-1.41-1.41L5.59 11 3.46 8.88zM10.5 1l-1-1h-5l-1 1H0v2h14V1h-3.5z"
                ></path>
            </svg>
        );
    }

    useEffect(() => {
        if (strategyId) {
            const fetchStrategyMetadata = async () => {
                const apiUrl = process.env.REACT_APP_BASE_URL as string + 'metadata/get';
                try {
                    const response = await fetch(
                        apiUrl,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            },
                            body: JSON.stringify({ strategyID: strategyId}), //use strategyId
                        }
                    );
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    const responseJSON = await response.json();
                    const responseData = responseJSON.response_data;
                    setMetaData(responseData);
                } catch (error) {
                    console.error("Error fetching strategy details:", error);
                }
            };
            fetchStrategyMetadata();
        }
    }, [strategyId]);

    useEffect(() => {
        metaCallBack(updatedMetaData);
    }, [updatedMetaData])


    const handleCallback = (childData: string) => {
        setUpdatedMetaData(childData);
    }

    return (
        <div className="strategy-settings-container">
            <div className="strategy-settings-container-first">
                <div className="strategy-settings-container-first-inner">
                    <div className="strategy-settings-title">Add Configuration</div>
                    <div className="strategy-settings-config-container">
                        <div className="strategy-settings-config-container-parts">
                            <div className="strategy-settings-config-name">
                                Configuration Name
                            </div>
                            <input
                                type="text"
                                className="strategy-settings-config-input-text"
                                placeholder="Enter text"
                                defaultValue={configName}
                                onChange={(e) => {
                                    setConfigName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="strategy-settings-config-container-parts">
                            <div className="strategy-settings-config-type">Type:</div>
                            <select className="strategy-settings-config-type-select"
                                defaultValue={configDataType}
                                onChange={(e) => {
                                    setConfigDataType(e.target.value);
                                }}
                            >
                                <option value="string">String</option>
                                <option value="number">Number</option>
                                <option value="boolean">Boolean</option>
                                <option value="map">Map</option>
                            </select>
                        </div>
                    </div>
                    <div className="strategy-settings-add-config">
                        <button
                            onClick={()=> {
                                handleClick();
                            }}
                        >
                            {" "}
                            <TickIcon />
                            Add Confguration
                        </button>
                    </div>
                    <hr />
                </div>
            </div>
            <div className="strategy-settings-container-second">
                <div className="strategy-settings-container-second-inner">
                    <div className="strategy-settings-container-second-grid-contatiner">
                        <div className="strategy-settings-container-second-grid strategy-settings-container-second-grid-title">
                            <div className="relative" style={{ width: width }}>
                                Attribute Name
                            </div>
                            <div className="relative w-100" ref={valuecomponentref}>
                                <span className="strategy-settings-container-second-grid-title-seperator"></span>
                                Value
                            </div>
                            <div className="relative">
                                <span className="strategy-settings-container-second-grid-title-seperator"></span>
                                Type
                            </div>
                        </div>
                        <div className="strategy-settings-container-second-grid">
                            <div ref={componentref}>
                                <AdminStrategySettingsData configName={configName} configDataType={configDataType} callback={handleChildFuncCallback}  metadata={metaData} width={widthOfValue} parentCallback={handleCallback} />
                            </div>
                            <div className="strategy-settings-add-btn-container">
                                {/* <div></div> */}
                                {/* <button className="strategy-settings-container-second-grid-add-new-field-btn">
                                    Add new field
                                </button>
                                <button className="strategy-settings-container-second-grid-add-new-field-btn">
                                    Add new field
                                </button>
                                <button className="strategy-settings-container-second-grid-add-new-field-btn">
                                    Add new field
                                </button>
                                <button className="strategy-settings-container-second-grid-add-new-field-btn">
                                    Add new field
                                </button> */}
                            </div>
                            <div className="strategy-settings-type-container">
                                <div className="strategy-settings-type-container-unit">
                                    <div>String</div>
                                    <div>
                                        <TrashIcon />
                                    </div>
                                </div>
                                <div className="strategy-settings-type-container-unit">
                                    <div>String</div>
                                    <div>
                                        <TrashIcon />
                                    </div>
                                </div>
                                <div className="strategy-settings-type-container-unit">
                                    <div>String</div>
                                    <div>
                                        <TrashIcon />
                                    </div>
                                </div>
                                <div className="strategy-settings-type-container-unit">
                                    <div>String</div>
                                    <div>
                                        <TrashIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminStrategySettings;
