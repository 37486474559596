import React, { useEffect, useState, ChangeEvent } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinkIcon from '@mui/icons-material/Link';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AddIcon from '@mui/icons-material/Add';
import tradeImg from '../../../assets/images/trade.jpg';
import Loader from '../../Dialogs/Loader';
import { uploadFileAPI, uploadImageAPI } from '../../../API/FileUploadAPI';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DeleteFaqApiCall } from '../../../API/AdminPageAPI';
interface AdminContentEditingProps {
  strategyId?: number;
}

interface NewFAQ {
  question: string;
  answer: string;
  isActive: boolean;
}

interface updatedFAQ {
  faqId: number;
  updates: {
    question: string;
    answer: string;
    isActive: boolean;
  };
}

interface ReceivedFAQ {
  faqId: number;
  question: string;
  answer: string;
  isActive: boolean;
  lastUpdated?: string;
  strategyId?: string;
}

interface StrategyDetails {
  id: string;
  is_live: boolean;
  strategy_name: string;
  minimum_investment_capital: string;
  CapitalDeployed: string;
  cagr_pct: number;
  Risk: string;
  Author: string;
  StrategyDescription: string;
  Trending: string;
  StrategyTag: string;
  AdminID: string;
  FAQs: NewFAQ[];
}

const AdminContentEditing: React.FC<AdminContentEditingProps> = ({
  strategyId,
}) => {
  const [formData, setFormData] = useState({
    detailsChanges: {
      strategyName: '',
      strategyShortDescription: '',
      strategyAuthor: '',
      trending: '',
      strategyImage: '',
      strategyTag: '',
      overview: '',
      risk: '',
    },
    capitalChanges: {
      minimumRequiredCapital: '',
    },
    CapitalDeployed: '',
    cagr_pct: '',
    insights_information: {
      blog_post: {
        object_url: '',
        text: '',
      },
      methodology: {
        object_url: '',
        text: '',
      },
      factsheets: {
        object_url: '',
        text: '',
      },
    },
    faqs: [] as Array<NewFAQ>,
    updateFaqs: [
      {
        faqId: 0,
        updates: {
          question: '',
          answer: '',
          isActive: true,
        },
      },
    ] as Array<updatedFAQ>,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(tradeImg);
  const [isLoading, setIsLoading] = useState(true);
  const [mincapital, setMinCapital] = useState('0');

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [selectedIcon, setSelectedIcon] = useState<File | null>(null);
  const [allFAQs, setAllFAQs] = useState<ReceivedFAQ[]>([]);
  const [updatedFAQs, setUpdatedFAQs] = useState<updatedFAQ[]>([]);
  const [newFAQs, setNewFAQs] = useState<NewFAQ[]>([]);
  //

  const [strategyImgpreview, setStrategyImgpreview] = useState<string | null>();

  const [selectedStrategyImage, setSelectedStrategyImage] =
    useState<File | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>('');

  const [blogPdfPreview, setblogPdfPreview] = useState<string | null>();
  const [blogSectionText, setBlogSectionText] = useState(
    formData.insights_information.blog_post.text
  );
  const [selectedBlogPdf, setselectedBlogPdf] = useState<File | null>(null);
  const [uploadedBlogPdfUrl, setuploadedBlogPdfUrl] = useState<string>('');

  const [methodologyPdfPreview, setmethodologyPdfPreview] = useState<
    string | null
  >();
  const [methodologySectionText, setMethodologySectionText] = useState('');
  const [selectedMethodologyPdf, setSelectedMethodologyPdf] =
    useState<File | null>(null);
  const [uploadedMethodologyPdfUrl, setuploadedMethodologyPdfUrl] =
    useState<string>('');

  const [factSheetPdfPreview, setfactSheetPdfPreview] = useState<
    string | null
  >();
  const [factSheetSectionText, setFactSheetSectionText] = useState('');
  const [selectedFactSheetPdf, setselectedFactSheetPdf] = useState<File | null>(
    null
  );
  const [uploadedFactSheetPdfUrl, setuploadedFactSheetPdfUrl] =
    useState<string>('');

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);
  const [selectedFaqId, setSelectedFaqId] = useState<number | null>(null);

  const handleDeleteFaq = async (faqId: number) => {
    try {
      const response = await DeleteFaqApiCall([faqId]);
      const statusMessage =
        response.response_data[0]?.status || 'FAQ deleted successfully';
      toast.success(statusMessage);
    } catch (error: any) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsConfirmDialogOpen(false);
    }
  };

  const handleConfirmDelete = (faqId: number) => {
    setSelectedFaqId(faqId);
    setIsConfirmDialogOpen(true);
  };

  const confirmDeletion = () => {
    if (selectedFaqId !== null) {
      handleDeleteFaq(selectedFaqId);
    }
  };

  //

  const handleUploadClick = () => {
    console.log('Selected Icon:', selectedIcon);
  };

  const [strategyDetails, setStrategyDetails] =
    useState<StrategyDetails | null>(null);
  const [faqs, setFaqs] = useState<NewFAQ[]>([]);

  useEffect(() => {
    // Fetch strategy details if strategyId is provided
    if (strategyId) {
      const fetchStrategyDetails = async () => {
        const apiUrl =
          (process.env.REACT_APP_BASE_URL as string) +
          'strategy/strategy-details';
        try {
          const response = await fetch(
            apiUrl,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                'x-api-key': process.env.REACT_APP_X_API_KEY as string,
              },
              body: JSON.stringify({ strategyId }),
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const responseJSON = await response.json();

          const responseData = responseJSON.response_data;
          // console.log(responseData);
          const latestFAQ = responseData.FAQs;
          setblogPdfPreview(
            responseData.Insights_Information?.blog_post_object_url
          );
          setmethodologyPdfPreview(
            responseData.Insights_Information?.methodology_object_url
          );
          setfactSheetPdfPreview(
            responseData.Insights_Information?.factsheets_object_url
          );
          setStrategyImgpreview(responseData?.strategy_image);
          setFormData({
            ...formData,
            detailsChanges: {
              ...formData.detailsChanges,
              strategyName: responseData?.strategy_name,
              trending: responseData?.Trending,
              strategyAuthor: responseData?.Author,
              strategyShortDescription: responseData?.StrategyDescription,
              // strategyImage: responseData?.strategy_image,
              strategyTag: responseData?.StrategyTag,
              risk: responseData.risk,
              overview: responseData?.Overview,
            },
            // faqs: responseData?.FAQs,
            cagr_pct: responseData?.cagr_pct,
            insights_information: {
              ...formData.insights_information,
              blog_post: {
                ...formData.insights_information.blog_post,
                // object_url:
                //   responseData.Insights_Information?.blog_post_object_url,
                text: responseData.Insights_Information?.blog_post_text,
              },
              methodology: {
                ...formData.insights_information.methodology,
                // object_url:
                //   responseData.Insights_Information?.methodology_object_url,
                text: responseData.Insights_Information?.methodology_text,
              },

              factsheets: {
                ...formData.insights_information.factsheets,
                // object_url:
                //   responseData.Insights_Information?.factsheets_object_url,
                text: responseData.Insights_Information?.factsheets_text,
              },
            },
          });
          setStrategyDetails(responseData as StrategyDetails);
          setMinCapital(responseData.minimum_investment_capital);
          if (responseData.FAQs.length > 0) {
            // setNewFAQs(responseData.FAQs);
            setAllFAQs(responseData.FAQs);
          } else {
            // setNewFAQs([{ question: '', answer: '', isActive: true }]);
          }
          //  setAllFAQs(responseData.FAQs);
          // console.log('strategy details', responseData, mincapital);
        } catch (error) {
          console.error('Error fetching strategy details:', error);
        }
        setIsLoading(false);
      };

      fetchStrategyDetails();
    }
  }, [strategyId]);
  // console.log(formData);

  const handleStrategyImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] as File | undefined;
    console.log(file);

    if (file) {
      const reader = new FileReader();
      setSelectedStrategyImage(file);
      reader.onload = () => {
        setStrategyImgpreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      // setSelectedStrategyImage(null);
      // setStrategyImgpreview(null);
    }
  };

  const handleRiskSelection = (choosenRisk: string) => {
    setFormData({
      ...formData,
      detailsChanges: {
        ...formData.detailsChanges,
        risk: choosenRisk,
      },
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevValues: any) => ({
      ...prevValues,
      detailsChanges: {
        ...formData.detailsChanges,
        [name]: value,
      },
    }));
  };

  const handleStrategyOverview = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      detailsChanges: {
        ...formData.detailsChanges,
        overview: e.target.value,
      },
    });
  };

  // console.log(strategyDetails);

  // const handleQnAChange = (index: any, event: any) => {
  //   const { name, value } = event.target;
  //   const updatedFAQs = [...formData.faqs];
  //   updatedFAQs[index] = { ...updatedFAQs[index], [name]: value };
  //   setFormData({
  //     ...formData,
  //     faqs: updatedFAQs,
  //   });
  // };

  // const handleQnAChange = (
  //   index: number,
  //   event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   faqID: number | undefined
  // ) => {
  //   const { name, value } = event.target;

  //   if (faqID === undefined || faqID === null) {
  //     setFormData((prevState) => {
  //       const updatedUpdateFaqs = prevState.updateFaqs.map((faq) => {
  //         if (faq.faqId === faqID) {
  //           return {
  //             ...faq,
  //             updates: {
  //               ...faq.updates,
  //               [name]: value,
  //             },
  //           };
  //         }
  //         return faq;
  //       });
  //       return {
  //         ...prevState,
  //         updateFaqs: updatedUpdateFaqs,
  //       };
  //     });
  //     setAllFAQs((prevFAQs) => {
  //       return prevFAQs.map((faq) => {
  //         if (faq.faqId === faqID) {
  //           return {
  //             ...faq,
  //             [name]: value,
  //           };
  //         }
  //         return faq;
  //       });
  //     });
  //   } else {
  //     setFormData((prevState) => {
  //       const updatedFAQs = prevState.faqs.map((faq, i) => {
  //         if (i === index) {
  //           return {
  //             ...faq,
  //             [name]: value,
  //           };
  //         }
  //         return faq;
  //       });
  //       return {
  //         ...prevState,
  //         faqs: updatedFAQs,
  //       };
  //     });
  //     setAllFAQs((prevFAQs) => {
  //       const updatedAllFAQs = [...prevFAQs];
  //       updatedAllFAQs[index] = {
  //         ...updatedAllFAQs[index],
  //         [name]: value,
  //       };
  //       return updatedAllFAQs;
  //     });
  //   }
  // };
  //

  // const handleQnAChange = (
  //   index: number,
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   faqId: number
  // ) => {
  //   const { name, value } = event.target;

  //   const updatedFAQList = allFAQs.map((faq, idx) => {
  //     if (idx === index) {
  //       return {
  //         ...faq,
  //         [name]: value,
  //       };
  //     }
  //     return faq;
  //   });

  //   setAllFAQs(updatedFAQList);
  //   const updatedFAQ = updatedFAQList.find((faq) => faq.faqId === faqId);
  //   if (updatedFAQ && !updatedFAQs.some((faq) => faq.faqId === faqId)) {
  //     setUpdatedFAQs((prev) => [...prev, updatedFAQ]);
  //   }
  // };

  function base64ToBinary(base64: string): ArrayBuffer {
    const binaryString = atob(base64.split(',')[1]);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  }

  function getBase64(file: any, callback: any) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const base64Data = reader.result?.toString() || '';
      const binaryData = base64ToBinary(base64Data);
      callback(binaryData);
    });
    reader.readAsDataURL(file);
  }

  const handleTextChange = (
    section: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const updatedFormData = { ...formData };
    // updatedFormData.insights_information[section + '_text'] = value;
    if (section === 'blog_post') {
      updatedFormData.insights_information.blog_post.text = value;
    } else if (section === 'methodology') {
      updatedFormData.insights_information.methodology.text = value;
    } else if (section === 'factsheets') {
      updatedFormData.insights_information.factsheets.text = value;
    }
    setFormData(updatedFormData);
  };

  const handleBlogPdfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File | undefined;
    console.log(file);
    if (file) {
      const reader = new FileReader();
      setselectedBlogPdf(file);
      reader.onload = () => {
        setblogPdfPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setselectedBlogPdf(null);
      setblogPdfPreview(null);
    }

    // if (file) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   // setselectedBlogPdf(file);
    //   reader.onload = (e: any) => {
    //     // setblogPdfPreview(e.target.result);
    //     setblogPdfPreview(file);
    //   };
    // } else {
    //   setselectedBlogPdf(null);
    //   setblogPdfPreview(null);
    // }
  };

  const handleMethodologyPdfChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] as File | undefined;

    if (file) {
      const reader = new FileReader();
      setSelectedMethodologyPdf(file);
      reader.onload = () => {
        setmethodologyPdfPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedMethodologyPdf(null);
      setmethodologyPdfPreview(null);
    }
  };
  const handleFactsheetPdfChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] as File | undefined;

    if (file) {
      const reader = new FileReader();
      setselectedFactSheetPdf(file);
      reader.onload = () => {
        setfactSheetPdfPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setselectedFactSheetPdf(null);
      setfactSheetPdfPreview(null);
    }
  };

  useEffect(() => {
    // Set mincapital after receiving data
    if (strategyDetails) {
      setMinCapital(strategyDetails.minimum_investment_capital);
      setFaqs(strategyDetails.FAQs);
      // console.log("  const [faqs, setFaqs] = useState<FAQ[]>([]);", faqs);
    }
  }, [strategyDetails]);

  const handleUpdateStrategy = async (e: React.FormEvent) => {
    e.preventDefault();

    const changes: Record<string, string> = {};
    // Object.entries(formData).forEach(([key, value]) => {
    //   if (
    //     value !== '' &&
    //     strategyDetails &&
    //     strategyDetails[key as keyof StrategyDetails] !== value
    //   ) {
    //     changes[key] = value;
    //   }
    // });

    const payload = {
      strategyId,
      formData,
    };

    console.log(formData);
    const apiUrl =
      (process.env.REACT_APP_BASE_URL as string) + 'strategy/update-strategy';
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          strategyId: strategyId,
          ...formData,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(response);

      alert(
        responseData.response_message
          ? responseData.response_message
          : responseData.error_message
      );

      console.log('Form submitted successfully!');
    } catch (error) {
      alert(error);
      console.error('Error submitting form:', error);
    }
  };

  // useEffect(() => {
  //   const fetchStrategyDetails = async () => {
  //     try {
  //       const response = await fetch(
  //         '',
  //         {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({ strategyId }),
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }

  //       const responseJSON = await response.json();
  //       const responseData = responseJSON.response_data;
  //       console.log(responseData);

  //       // setStrategyDetails(responseData as StrategyDetails);
  //       setFormData({
  //         ...formData,
  //         strategyName: responseData.strategy_name,
  //         trending: responseData.Trending,
  //         strategyAuthor: responseData.Author,
  //         strategyShortDescription: responseData.StrategyDescription,
  //         strategyImage: responseData.strategy_image,
  //         strategyTag: responseData.StrategyTag,
  //         faqs: responseData.FAQs,
  //         cagr_pct:responseData.cagr_pct
  //       });
  //     } catch (error) {
  //       console.error('Error fetching strategy details:', error);
  //     }
  //   };
  //   fetchStrategyDetails();
  // }, [strategyId]);

  //

  useEffect(() => {
    if (selectedStrategyImage !== null) {
      getBase64(selectedStrategyImage, async function (binaryData: any) {
        const response = await uploadImageAPI(
          'strategy',
          selectedStrategyImage,
          binaryData
        );
        const imageData = await response;
        console.log(imageData);

        setUploadedImageUrl(imageData.object_url);
        console.log(imageData.object_url);
        if (imageData.object_url !== '') {
          console.log(imageData.object_url, 'undertae');

          setFormData({
            ...formData,
            detailsChanges: {
              ...formData.detailsChanges,
              strategyImage: imageData.object_url,
            },
          });
        }
      });
    }
    // console.log(formData);

    if (uploadedImageUrl !== '') {
      setFormData({
        ...formData,
        detailsChanges: {
          ...formData.detailsChanges,
          strategyImage: uploadedImageUrl,
        },
      });
    }

    // console.log(formData);
  }, [selectedStrategyImage]);

  useEffect(() => {
    if (selectedBlogPdf != null) {
      getBase64(selectedBlogPdf, async function (binaryData: any) {
        const response = await uploadFileAPI(
          'strategy',
          selectedBlogPdf,
          binaryData
        );
        const imageData = await response;
        setuploadedBlogPdfUrl(imageData.object_url);
        console.log(imageData.object_url);
      });
    }
  }, [selectedBlogPdf]);

  useEffect(() => {
    if (selectedMethodologyPdf != null) {
      getBase64(selectedMethodologyPdf, async function (binaryData: any) {
        const response = await uploadFileAPI(
          'strategy',
          selectedMethodologyPdf,
          binaryData
        );
        const imageData = await response;
        setuploadedMethodologyPdfUrl(imageData.object_url);
        console.log(imageData.object_url);
      });
    }
  }, [selectedMethodologyPdf]);
  useEffect(() => {
    if (selectedFactSheetPdf != null) {
      getBase64(selectedFactSheetPdf, async function (binaryData: any) {
        const response = await uploadFileAPI(
          'strategy',
          selectedFactSheetPdf,
          binaryData
        );
        const imageData = await response;
        setuploadedFactSheetPdfUrl(imageData.object_url);
        console.log(imageData.object_url);
      });
    }
  }, [selectedFactSheetPdf]);

  const handleQnAChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    faqId: number
  ) => {
    const { name, value } = event.target;
    console.log(value);

    const updatedFAQList = allFAQs.map((faq, idx) => {
      if (idx === index) {
        return {
          ...faq,
          [name]: value,
        };
      }
      return faq;
    });
    console.log(updatedFAQList);
    setAllFAQs(updatedFAQList);
    const updatedFAQ = updatedFAQList.find((faq) => faqId === faq.faqId);
    console.log(updatedFAQ);
    // setUpdatedFAQs((prev: any) => [
    //   ...prev,
    //   {
    //     faqId: faqId,
    //     updates: {
    //       question: updatedFAQ?.question,
    //       answer: updatedFAQ?.answer,
    //       isActive: updatedFAQ?.isActive,
    //     },
    //   },
    // ]);

    // if (updatedFAQ && updatedFAQs.map((faq) => faq.faqId === faqId)) {
    //   setUpdatedFAQs((prev: any) => [
    //     ...prev,
    //     {
    //       faqId: faqId,
    //       updates: {
    //         question: updatedFAQ.question,
    //         answer: updatedFAQ.answer,
    //         isActive: updatedFAQ.isActive,
    //       },
    //     },
    //   ]);
    // }

    if (updatedFAQ) {
      setUpdatedFAQs((prev) => {
        // Remove previous data for the same faqId
        const filteredUpdatedFAQs = prev.filter((faq) => faq.faqId !== faqId);
        // Add new data for the faqId
        return [
          ...filteredUpdatedFAQs,
          {
            faqId: faqId,
            updates: {
              question: updatedFAQ.question,
              answer: updatedFAQ.answer,
              isActive: updatedFAQ.isActive,
            },
          },
        ];
      });
    }
  };
  const addQna = () => {
    const newFAQ: NewFAQ = {
      question: '',
      answer: '',
      isActive: true,
    };

    setNewFAQs((prev) => [...prev, newFAQ]);
  };

  const removeQna = (index: number) => {
    // const newFaqs = [...formData.faqs];
    // console.log(newFaqs.splice(index, 1));
    // console.log(index);

    setNewFAQs((prev) => prev.filter((_, i) => i !== index));
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      faqs: newFAQs,
    }));
  }, [newFAQs, allFAQs]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      capitalChanges: {
        minimumRequiredCapital: mincapital,
      },
    }));
  }, [mincapital]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      updateFaqs: updatedFAQs,
    }));
  }, [updatedFAQs]);

  useEffect(() => {
    if (uploadedBlogPdfUrl !== '') {
      setFormData((prevState) => ({
        ...prevState,
        insights_information: {
          ...prevState.insights_information,
          blog_post: {
            ...prevState.insights_information.blog_post,
            object_url: uploadedBlogPdfUrl,
          },
        },
      }));
    }

    if (uploadedMethodologyPdfUrl !== '') {
      setFormData((prevState) => ({
        ...prevState,
        insights_information: {
          ...prevState.insights_information,
          methodology: {
            ...prevState.insights_information.methodology,
            object_url: uploadedMethodologyPdfUrl,
            // text: methodologySectionText,
          },
        },
      }));
    }

    if (uploadedFactSheetPdfUrl !== '') {
      setFormData((prevState) => ({
        ...prevState,
        insights_information: {
          ...prevState.insights_information,
          factsheets: {
            ...prevState.insights_information.factsheets,
            object_url: uploadedFactSheetPdfUrl,
            // text: factSheetSectionText,
          },
        },
      }));
    }
    // console.log(uploadedBlogPdfUrl);
  }, [
    blogSectionText,
    methodologySectionText,
    factSheetSectionText,
    uploadedMethodologyPdfUrl,
    uploadedBlogPdfUrl,
    uploadedFactSheetPdfUrl,
  ]);

  if (isLoading) {
    return <Loader />;
  }

  // console.log(formData);
  // console.log(newFAQs);
  // console.log(updatedFAQs);

  return (
    <>
      <ToastContainer />
      {strategyDetails && (
        <div>
          <form className='admin-content-editing-container'>
            <div className='admin-content-editing-heading'>
              <span className='admin-strategy-heading'>
                General Informations
              </span>
              <div className='id-editor'>
                <span>ID: {strategyId}</span>
              </div>
            </div>
            <div className='admin-content-editing-section'>
              <div className='horizontal-line'></div>
              <div className='import-section'>
                <div className='import-button-section'>
                  {!selectedImage && (
                    <label htmlFor='imageInput' className='import-button'>
                      <FileUploadIcon />
                      Import
                    </label>
                  )}
                  {strategyImgpreview ? (
                    <>
                      {' '}
                      <div className='edit-image-section'>
                        <img
                          className='selected-image'
                          src={strategyImgpreview}
                          alt='Selected Imagez'
                        />
                        <label
                          htmlFor='strategyImgInput'
                          className='edit-image-icon'
                        >
                          <EditIcon />
                        </label>
                      </div>
                    </>
                  ) : formData.detailsChanges.strategyImage ? (
                    <>
                      <div className='edit-image-section'>
                        <img
                          className='selected-image'
                          src={formData.detailsChanges.strategyImage}
                          alt='Selected Imagez'
                        />
                        <label
                          htmlFor='strategyImgInput'
                          className='edit-image-icon'
                        >
                          <EditIcon
                            style={{
                              height: '15px',
                              width: '15px',
                              padding: '2px',
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              backgroundColor: '#fff',
                              border: 'solid',
                              borderRadius: '50%',
                            }}
                          />
                        </label>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <input
                    type='file'
                    id='strategyImgInput'
                    style={{ display: 'none' }}
                    accept='image/*'
                    onChange={(e) => handleStrategyImageChange(e)}
                  />

                  {/* {formData.detailsChanges.strategyImage && (
                    <div className='edit-image-section'>
                      <img
                        className='selected-image'
                        src={formData.detailsChanges.strategyImage}
                        alt='Selected Imagez'
                      />
                      <label
                        htmlFor='strategyImgInput'
                        className='edit-image-icon'
                      >
                        <EditIcon
                          style={{
                            height: '15px',
                            width: '15px',
                            padding: '2px',
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            backgroundColor: '#fff',
                            border: 'solid',
                            borderRadius: '50%',
                          }}
                        />
                        <input
                          type='file'
                          id='strategyImgInput'
                          style={{ display: 'none' }}
                          accept='image/*'
                          onChange={handleStrategyImageChange}
                        />
                      </label>
                    </div>
                  )} */}

                  <span>
                    {selectedImage ? '(Change Image)' : '(Select Image)'}
                  </span>
                </div>

                <div className='strategy-name-info'>
                  <label className='admin-strategy-heading'>
                    Strategy Name:
                  </label>
                  <input
                    type='text'
                    name='strategyName'
                    value={formData.detailsChanges.strategyName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className='strategy-tag'>
                  <label className='admin-strategy-heading'>
                    Strategy Tag:
                  </label>
                  <div>
                    {/*  */}

                    <label>
                      <input
                        type='radio'
                        name='strategyTag'
                        value='New'
                        checked={formData.detailsChanges.strategyTag === 'New'}
                        onChange={handleInputChange}
                      />
                      New
                    </label>

                    <label>
                      <input
                        type='radio'
                        name='strategyTag'
                        value='Popular'
                        checked={
                          formData.detailsChanges.strategyTag === 'Popular'
                        }
                        onChange={handleInputChange}
                      />
                      Popular
                    </label>

                    <label>
                      <input
                        type='radio'
                        name='strategyTag'
                        value='High Return'
                        checked={
                          formData.detailsChanges.strategyTag === 'High Return'
                        }
                        onChange={handleInputChange}
                      />
                      High Return
                    </label>
                  </div>
                </div>
              </div>

              {/*  */}
              <div className='admin-content-middle-section'>
                <div className='admin-content-middle-individual-section'>
                  <label
                    className='admin-strategy-heading'
                    htmlFor='authorName'
                  >
                    Author Name:
                  </label>
                  <textarea
                    id='authorName'
                    rows={4}
                    cols={50}
                    name='strategyAuthor'
                    value={formData.detailsChanges.strategyAuthor}
                    onChange={handleInputChange}
                    placeholder='Type Something...'
                  />
                </div>

                {/*  */}
                <div className='admin-content-middle-individual-section'>
                  <label
                    className='admin-strategy-heading'
                    htmlFor='strategyShortDescription'
                  >
                    Strategy Description:
                  </label>
                  <textarea
                    id='strategyShortDescription'
                    rows={4}
                    cols={50}
                    name='strategyShortDescription'
                    value={formData.detailsChanges.strategyShortDescription}
                    onChange={handleInputChange}
                    placeholder='Type Something...'
                  />
                </div>
                {/*  */}

                <div className='admin-content-middle-individual-section'>
                  <label
                    className='trending-header'
                    htmlFor='trendingDescription'
                  >
                    <article>
                      Trending &nbsp;
                      {/* <EditIcon className='edit-icon' /> */}
                    </article>{' '}
                    <span>
                      (Note:Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit, sed do eiusmod tempor incididunt...)
                    </span>
                  </label>
                  <textarea
                    id='trendingDescription'
                    rows={4}
                    cols={50}
                    name='trending'
                    value={formData.detailsChanges.trending}
                    onChange={handleInputChange}
                    placeholder='Type Something...'
                  />
                </div>

                {/* editor section */}
                <div
                  className='admin-content-middle-individual-section'
                  style={{ marginBottom: '12px' }}
                >
                  <label
                    className='admin-strategy-heading'
                    htmlFor='strategyDescription'
                  >
                    <article>
                      Strategy Overview: &nbsp;
                      {/* <EditIcon className='edit-icon' /> */}
                    </article>{' '}
                  </label>
                  <textarea
                    id='overview'
                    rows={4}
                    cols={50}
                    name='overview'
                    value={formData.detailsChanges.overview}
                    onChange={handleStrategyOverview}
                    placeholder='Description...'
                  />
                  {/* <div style={{ marginTop: '10px' }} className=''>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        placeholder='Your Description...'
                        editorClassName='custom-editor-class'
                        wrapperClassName='custom-wrapper-class'
                      />
                      <button
                        className='clear-all-btn'
                        // onClick={clearEditorContent}
                      >
                        <CloseOutlinedIcon /> Clear All
                      </button>
                    </div> */}
                </div>
                {/* editor section */}

                {/* <div className='admin-content-editor'>
                  <label
                    className='admin-strategy-heading'
                    htmlFor='strategyDescription'
                  >
                    Strategy Overview:
                  </label>
                  <textarea
                    id='overview'
                    rows={4}
                    cols={50}
                    placeholder='Description...'
                  />
                </div> */}

                {/*  qna section */}
                <div className='ques-ans-section'>
                  <span className='admin-strategy-heading'>
                    Q&As (in Dropdown)
                  </span>
                  <div className='qna-ans'>
                    {allFAQs
                      .filter((faq) => faq.isActive)
                      .map((faq, index) => (
                        <div key={faq.faqId} className='qna mb-10 flex'>
                          <div className='w-100'>
                            <div className='flex w-100'>
                              <label htmlFor={`question-${index}`}>
                                Question
                              </label>
                              <input
                                type='text'
                                style={{ height: '35px' }}
                                id={`question-${index}`}
                                name='question'
                                placeholder='Type Something...'
                                value={faq.question || ''}
                                onChange={(event) =>
                                  handleQnAChange(index, event, faq.faqId)
                                }
                              />
                            </div>

                            <div className='flex w-100'>
                              <label htmlFor={`answer-${index}`}>Answer</label>
                              <textarea
                                id={`answer-${index}`}
                                name='answer'
                                value={faq.answer || ''}
                                placeholder='Type Something...'
                                rows={4}
                                cols={50}
                                // onChange={(event) =>
                                //   handleQnAChange(index, event, faq.faqId)
                                // }

                                onChange={(event) => {
                                  const { value } = event.target;
                                  console.log(value);

                                  setAllFAQs((prev) => {
                                    const updatedAllNewFAQs = [...prev];
                                    updatedAllNewFAQs[index].answer = value;
                                    return updatedAllNewFAQs;
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <span
                            onClick={() => handleConfirmDelete(faq.faqId)}
                            className='flex'
                            style={{
                              margin: '10px 0 0 10px',
                              cursor: 'pointer',
                            }}
                          >
                            <DeleteForeverIcon />
                          </span>
                        </div>
                      ))}
                    {newFAQs
                      .filter((faq) => faq.isActive)
                      .map((faq, index) => (
                        <div key={`new-${index}`} className='qna flex mb-10'>
                          <div className='w-100'>
                            <div className='flex w-100'>
                              <label htmlFor={`new-question-${index}`}>
                                Question
                              </label>
                              <input
                                type='text'
                                style={{ height: '35px' }}
                                id={`new-question-${index}`}
                                name='question'
                                placeholder='Type Something...'
                                value={faq.question}
                                onChange={(event) => {
                                  const { value } = event.target;
                                  setNewFAQs((prev) => {
                                    const updatedNewFAQs = [...prev];
                                    updatedNewFAQs[index].question = value;
                                    return updatedNewFAQs;
                                  });
                                }}
                              />
                            </div>

                            <div className='flex w-100'>
                              <label htmlFor={`new-answer-${index}`}>
                                Answer
                              </label>
                              <textarea
                                id={`new-answer-${index}`}
                                name='answer'
                                value={faq.answer}
                                placeholder='Type Something...'
                                rows={4}
                                cols={50}
                                onChange={(event) => {
                                  const { value } = event.target;
                                  setNewFAQs((prev) => {
                                    const updatedNewFAQs = [...prev];
                                    updatedNewFAQs[index].answer = value;
                                    return updatedNewFAQs;
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <span
                            // onClick={() => handleConfirmDelete(296)}
                            onClick={() => removeQna(index)}
                            className='flex'
                            style={{
                              margin: '10px 0 0 10px',
                              cursor: 'pointer',
                            }}
                          >
                            <DeleteForeverIcon />
                          </span>
                        </div>
                      ))}
                  </div>
                  <br />
                  <div className='add-more-btn flex' onClick={addQna}>
                    <AddIcon /> Add Q/A
                  </div>
                </div>

                {/* capital information section */}
                <div className='capital-information-section'>
                  <span className='admin-strategy-heading'>
                    Capital Information
                  </span>
                  <div className='horizontal-line'></div>
                  <div className='capital-info-part'>
                    <div className='capital-info-min-cap'>
                      <span className='admin-strategy-heading'>
                        Minimum Capital
                      </span>
                      <div className='admin-strategy-info'>
                        <span className='minimum-capital-input'>
                          <CurrencyRupeeIcon />
                          <input
                            type='string'
                            name='mincapital'
                            value={mincapital}
                            onChange={(e) => setMinCapital(e.target.value)}
                            // value={Number(strategyDetails.minimum_investment_capital)}
                            id='mincapital'
                          />
                        </span>
                        <p className='dummy-text'>
                          (Note: Lorem ipsum dolor sit amet Lorem ipsum dolor
                          sit ametLorem ipsum dolor sit ametlorem ipsum dolor
                          sit)
                        </p>
                      </div>{' '}
                    </div>
                    {/* <div className='capital-info-min-cap'>
                      <span className='admin-strategy-heading'>
                        Capital Deployed
                      </span>
                      <div className='admin-strategy-info'>
                        <span className='minimum-capital-input'>
                          <CurrencyRupeeIcon />
                          <input
                            type='number'
                            name='mincapital'
                            // value={mincapital}
                            value={strategyDetails?.CapitalDeployed}
                            id='mincapital'
                          />
                        </span>
                        <p className='dummy-text'>
                          (Note: Lorem ipsum dolor sit amet Lorem ipsum dolor
                          sit ametLorem ipsum dolor sit ametlorem ipsum dolor
                          sit)
                        </p>
                      </div>{' '}
                    </div>
                    <div className='capital-info-min-cap'>
                      <span className='admin-strategy-heading'>CAGR %</span>
                      <div className='admin-strategy-info'>
                        <span className='minimum-capital-input'>
                          <input
                            type='number'
                            name='cagr'
                            id='cagr'
                            // value={cagr}
                            value={strategyDetails?.cagr_pct}
                          />
                        </span>
                        <span className='dummy-text'>
                          (Note: Lorem ipsum dolor sit amet Lorem ipsum dolor
                          sit ametLorem ipsum dolor sit ametlorem ipsum dolor
                          sit)
                        </span>
                      </div>{' '}
                    </div> */}

                    {/* risk section */}
                    <div className='capital-info-min-cap  admin-content-risk-section'>
                      <span className='admin-strategy-heading'>Risk</span>
                      <div className='admin-risk-content'>
                        <span className='risk-options'>
                          <div
                            className={`risk-option ${
                              formData.detailsChanges.risk === 'Low Risk'
                                ? 'selected'
                                : ''
                            }`}
                            onClick={() => handleRiskSelection('Low Risk')}
                          >
                            <svg
                              className='low'
                              xmlns='http://www.w3.org/2000/svg'
                              width='8'
                              height='15'
                              viewBox='0 0 8 15'
                              fill='none'
                            >
                              <path
                                d='M5 11.2825L5 0.610352L3 0.610352L3 11.2825L0 11.2825L4 14.828L8 11.2825H5Z'
                                fill='#2ACD1C'
                              />
                            </svg>
                            Low
                          </div>
                          <div
                            className={`risk-option ${
                              // selectedRisk === 'Medium' ? 'selected' : ''
                              formData.detailsChanges.risk === 'Medium Risk'
                                ? 'selected'
                                : ''
                            }`}
                            onClick={() => handleRiskSelection('Medium Risk')}
                          >
                            <svg
                              className='medium'
                              xmlns='http://www.w3.org/2000/svg'
                              width='14'
                              height='17'
                              viewBox='0 0 14 17'
                              fill='none'
                            >
                              <path
                                d='M11 13.171V6.94191H9V13.171H6L10 16.7165L14 13.171H11ZM4 0.72168L0 4.26721H3V10.4963H5V4.26721H8L4 0.72168Z'
                                fill='#E7AF1D'
                              />
                            </svg>
                            Medium
                          </div>
                          <div
                            className={`risk-option ${
                              // selectedRisk === 'High' ? 'selected' : ''
                              formData.detailsChanges.risk === 'High Risk'
                                ? 'selected'
                                : ''
                            }`}
                            onClick={() => handleRiskSelection('High Risk')}
                          >
                            <svg
                              className='high'
                              xmlns='http://www.w3.org/2000/svg'
                              width='8'
                              height='15'
                              viewBox='0 0 8 15'
                              fill='none'
                            >
                              <path
                                d='M5 4.04467L5 14.7168H3L3 4.04467L0 4.04467L4 0.499138L8 4.04467H5Z'
                                fill='#F82929'
                              />
                            </svg>
                            High
                          </div>
                        </span>
                        <span className='dummy-text'>
                          (Note: Lorem ipsum dolor sit amet Lorem ipsum dolor
                          sit ametLorem ipsum dolor sit ametLorem ipsum dolor
                          sit)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* insight info section */}
                <div className='insights-information-section'>
                  <span className='admin-strategy-heading'>
                    Insights Information
                  </span>
                  <div className='horizontal-line'></div>
                  <div className='insights-information-content'>
                    <div className='blog-post-section'>
                      <div className='icon-section'>
                        {/* {blogPdfPreview ? (
                          <div className='blog-image-section'>
                            <img
                              className='blog-selected-image'
                              src={blogPdfPreview}
                              // src={
                              //   formData.insights_information
                              //     .methodology_object_url
                              // }
                              alt='Selected Imagez'
                            />
                            <label
                              htmlFor='blogPostInput'
                              className='edit-blog-image'
                            >
                              <EditIcon
                                style={{
                                  height: '15px',
                                  width: '15px',
                                  padding: '2px',
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  backgroundColor: '#fff',
                                  border: 'solid',
                                  borderRadius: '50%',
                                }}
                              />
                              <input
                              type='file'
                              id='blogPostInput'
                              style={{ display: 'none' }}
                              // onChange={handleIconChange}
                              accept='image/*'
                              onChange={(event) =>
                                handleImageChange(
                                  'blog_post',
                                  event as React.ChangeEvent<HTMLInputElement>
                                )
                              }
                            />
                            </label>
                          </div>
                        ) : (
                          'wwe'
                        )} */}
                        {blogPdfPreview ? (
                          <div className='blog-image-section'>
                            <img
                              className='blog-selected-image'
                              src={
                                blogPdfPreview
                                  ? blogPdfPreview
                                  : formData.insights_information.blog_post
                                      .object_url
                              }
                              alt='Selected Imagez'
                            />
                            <label
                              htmlFor='blogPostInputImg'
                              className='edit-blog-image'
                            >
                              <EditIcon
                                style={{
                                  height: '15px',
                                  width: '15px',
                                  padding: '2px',
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  backgroundColor: '#fff',
                                  border: 'solid',
                                  borderRadius: '50%',
                                }}
                              />
                              <input
                                type='file'
                                id='blogPostInputImg'
                                style={{ display: 'none' }}
                                // onChange={handleIconChange}
                                accept='.pdf'
                                // onChange={(event) =>
                                //   handleImageChange('blog_post', event)
                                // }
                                onChange={handleBlogPdfChange}
                              />
                            </label>
                          </div>
                        ) : (
                          <>
                            <label
                              htmlFor='blogPostInput'
                              className='choose-icon-button'
                            >
                              <FileUploadIcon /> Upload File
                            </label>
                            <input
                              type='file'
                              id='blogPostInput'
                              style={{ display: 'none' }}
                              // onChange={handleIconChange}
                              accept='.pdf'
                              // onChange={(event) =>
                              //   handleImageChange(
                              //     'blog_post',
                              //     event as React.ChangeEvent<HTMLInputElement>
                              //   )
                              // }
                              onChange={handleBlogPdfChange}
                            />
                          </>
                        )}
                      </div>

                      <div className='blog-info'>
                        <span className='admin-strategy-heading'>
                          Blog Post
                        </span>
                        <br />
                        <input
                          type='text'
                          placeholder='Your description'
                          value={formData.insights_information.blog_post.text}
                          onChange={(event) =>
                            handleTextChange('blog_post', event)
                          }
                          maxLength={70}
                          required
                        />
                        <span style={{ display: 'flex', fontSize: '10px' }}>
                          (Max 70 Characters Allowed)
                        </span>

                        {/* <div className='button-row'>
                          <button onClick={handleUploadClick}>
                            {' '}
                            <FileUploadIcon /> Upload
                          </button>
                          <button style={{ width: '72.5%' }}>
                            <LinkIcon />
                            Add Link
                          </button>
                        </div> */}
                      </div>
                    </div>
                    {/*  */}
                    <div className='blog-post-section'>
                      <div className='icon-section'>
                        {methodologyPdfPreview ? (
                          <div className='blog-image-section'>
                            <img
                              className='blog-selected-image'
                              src={
                                methodologyPdfPreview
                                  ? methodologyPdfPreview
                                  : formData.insights_information.methodology
                                      .object_url
                              }
                              // src={
                              //   formData.insights_information
                              //     .methodology_object_url
                              // }
                              alt='Selected Imagez'
                            />
                            <label
                              htmlFor='methodologyImgInput'
                              className='edit-blog-image'
                            >
                              <EditIcon
                                style={{
                                  height: '15px',
                                  width: '15px',
                                  padding: '2px',
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  backgroundColor: '#fff',
                                  border: 'solid',
                                  borderRadius: '50%',
                                }}
                              />
                              <input
                                type='file'
                                id='methodologyImgInput'
                                style={{ display: 'none' }}
                                accept='.pdf'
                                // onChange={(event) =>
                                //   handleImageChange('methodology', event)
                                // }
                                onChange={handleMethodologyPdfChange}
                              />
                            </label>
                          </div>
                        ) : (
                          <>
                            <label
                              htmlFor='iconInput'
                              className='choose-icon-button'
                            >
                              <FileUploadIcon /> Upload File
                            </label>
                            <input
                              type='file'
                              id='iconInput'
                              style={{ display: 'none' }}
                              accept='.pdf'
                              // onChange={(event) =>
                              //   handleImageChange('methodology', event)
                              // }
                              onChange={handleMethodologyPdfChange}
                            />
                          </>
                        )}
                      </div>

                      <div className='blog-info'>
                        <span className='admin-strategy-heading'>
                          Methodology
                        </span>
                        <br />
                        <input
                          type='text'
                          placeholder='Your description'
                          value={formData.insights_information.methodology.text}
                          onChange={(event) =>
                            handleTextChange('methodology', event)
                          }
                          maxLength={70}
                          required
                        />
                        <span style={{ display: 'flex', fontSize: '10px' }}>
                          (Max 70 Characters Allowed)
                        </span>

                        {/* <div className='button-row'>
                          <button onClick={handleUploadClick}>
                            {' '}
                            <FileUploadIcon /> Upload
                          </button>
                          <button style={{ width: '72.5%' }}>
                            <LinkIcon />
                            Add Link
                          </button>
                        </div> */}
                      </div>
                    </div>

                    {/*  */}

                    <div className='blog-post-section'>
                      <div className='icon-section'>
                        {factSheetPdfPreview ? (
                          <div className='blog-image-section'>
                            <img
                              className='blog-selected-image'
                              src={
                                factSheetPdfPreview
                                  ? factSheetPdfPreview
                                  : formData.insights_information.factsheets
                                      .object_url
                              }
                              alt='Selected Imagez'
                            />
                            <label
                              htmlFor='factSheetInputImg'
                              className='edit-blog-image'
                            >
                              <EditIcon
                                style={{
                                  height: '15px',
                                  width: '15px',
                                  padding: '2px',
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  backgroundColor: '#fff',
                                  border: 'solid',
                                  borderRadius: '50%',
                                }}
                              />
                              <input
                                type='file'
                                id='factSheetInputImg'
                                style={{ display: 'none' }}
                                // onChange={(event) =>
                                //   handleImageChange('factsheets', event)
                                // }
                                onChange={handleFactsheetPdfChange}
                                accept='.pdf'
                              />
                            </label>
                          </div>
                        ) : (
                          <>
                            {' '}
                            <label
                              htmlFor='iconInput'
                              className='choose-icon-button'
                            >
                              <FileUploadIcon /> Upload File
                            </label>
                            <input
                              type='file'
                              id='iconInput'
                              style={{ display: 'none' }}
                              // onChange={(event) =>
                              //   handleImageChange('factsheets', event)
                              // }
                              onChange={handleFactsheetPdfChange}
                              accept='.pdf'
                            />
                          </>
                        )}
                      </div>

                      <div className='blog-info'>
                        <span className='admin-strategy-heading'>
                          Factsheets
                        </span>
                        <br />
                        <input
                          type='text'
                          placeholder='Your description'
                          value={formData.insights_information.factsheets.text}
                          onChange={(event) =>
                            handleTextChange('factsheets', event)
                          }
                          maxLength={70}
                          required
                        />
                        <span style={{ display: 'flex', fontSize: '10px' }}>
                          (Max 70 Characters Allowed)
                        </span>

                        {/* <div className='button-row'>
                          <button onClick={handleUploadClick}>
                            {' '}
                            <FileUploadIcon /> Upload
                          </button>
                          <button style={{ width: '72.5%' }}>
                            <LinkIcon />
                            Add Link
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <button
            className='admin-create-strategy-btn'
            onClick={handleUpdateStrategy}
          >
            Update Strategy
          </button>

          {isConfirmDialogOpen && (
            <div className='confirm-dialog'>
              <p>Are you sure you want to delete this FAQ?</p>
              <button onClick={confirmDeletion}>Yes</button>
              <button onClick={() => setIsConfirmDialogOpen(false)}>No</button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AdminContentEditing;
