export async function deleteStrategyAPICall(strategyId: string): Promise<void> {
  const apiUrl =
    (process.env.REACT_APP_BASE_URL as string) + 'strategy/delete-strategy';
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify({
        strategyId: strategyId,
        isDeleted: true,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete strategy');
    }
  } catch (error) {
    console.error('Error deleting strategy:', error);
    throw error;
  }
}

//to get admin table data
export interface AdminTableDataItem {
  id: string;
  is_live: boolean;
  strategy_name: string;
  minimum_investment_capital: number;
  capital_deployed: number;
  cagr_pct: number;
  risk: string;
  strategy_id: number;
  strategy_image: string;
}

export const fetchAdminTableData = async (
  apiUrl: string
): Promise<AdminTableDataItem[]> => {
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData.response_data as AdminTableDataItem[];
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

//fetching subscribed user details based on strategy name

export interface UserDetailsByStartegyNameApiResponse {
  user_id: number;
  strategy_id: string;
  strategy_name: string;
  date_joined: string;
  is_deleted: boolean;
  is_active: boolean;
  name: string;
  email: string;
  lots: number;
}

export const getUserDetailsByStrategyName = async (
  strategyName: string
): Promise<UserDetailsByStartegyNameApiResponse[]> => {
  const url =
    (process.env.REACT_APP_BASE_URL as string) + 'userdetailsbystrategyname';
  const body = JSON.stringify({ strategyName: strategyName });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
    return responseData.response_data as UserDetailsByStartegyNameApiResponse[];
  } catch (error) {
    console.error('Error fetching user strategy details:', error);
    throw new Error('An error occurred while fetching user strategy details');
  }
};

//Message
// :
// "You have Free plan"
// Status_code
// :
// 406

export interface UserSubscriptionDetails {
  Message: string;
  Status_code: number;
}

export async function EditLotsAPICall(
  strategyId: string,
  userId: number,
  lots: number
): Promise<UserSubscriptionDetails> {
  const apiUrl =
    (process.env.REACT_APP_BASE_URL as string) + 'user-admin-edit_lots';
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify({
        strategy_id: strategyId,
        user_id: userId,
        lots: lots,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update lots');
    } else {
      const responseData = await response.json();
      return responseData as UserSubscriptionDetails;
    }
  } catch (error) {
    console.error('Error in update lots:', error);
    throw error;
  }

  // Replace with the actual return value
}

//delete faq's

interface DeleteResponseData {
  faqId: number;
  status: string;
}

interface DeleteFaqResponse {
  response_code: number;
  error_code: string;
  error_message: string;
  request_id: string;
  time_taken: number;
  request_timestamp: string;
  response_message: string;
  response_data: DeleteResponseData[];
}

export const DeleteFaqApiCall = async (
  faqIds: number[]
): Promise<DeleteFaqResponse> => {
  const apiUrl = (process.env.REACT_APP_BASE_URL as string) + 'delete-faq';
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ faqIds }),
  });

  if (!response.ok) {
    throw new Error(`Error : ${response.statusText}`);
  }

  const data: DeleteFaqResponse = await response.json();
  return data;
};
