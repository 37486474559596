import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSpring, animated } from "@react-spring/web";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { TransitionProps } from "@mui/material/transitions";
import Collapse from "@mui/material/Collapse";
import { alpha, styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses,
} from "@mui/x-tree-view/TreeItem";

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

interface ButtonProps {
  onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

function PenIcon({ onClick }: ButtonProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      style={{ marginLeft: 5 }}
      onClick={onClick}
    >
      <path
        fill="#000"
        fillOpacity="0.7"
        d="M0 9.469v2.491h2.5l7.372-7.349-2.5-2.491L0 9.469zm1.946 1.163h-.613v-.612L7.373 4l.613.611-6.04 6.02zm9.859-8.884L10.245.193A.656.656 0 009.772 0a.657.657 0 00-.467.193l-1.22 1.216 2.5 2.491 1.22-1.216a.66.66 0 000-.936z"
      ></path>
    </svg>
  );
}

const CustomTreeItem = React.forwardRef(
  (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
  )
);

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

interface AdminStrategySettingsDataProps {
  metadata: string;
  width?: number;
  parentCallback: (data: string) => void;
  callback: (fn: () => void) => void;
  configName?: string;
  configDataType?: string;
}

const AdminStrategySettingsData: React.FC<AdminStrategySettingsDataProps> = ({
  metadata,
  width,
  parentCallback,
  callback,
  configName,
  configDataType,
}) => {
  let counter = 1;

  const generateUniqueLabel = () => {
    const id = counter.toString();
    counter++;
    return id;
  };

  interface TreeNode {
    label: string;
    children?: TreeNode[] | undefined;
    key?: any;
    data?: any;
  }

  const createTreeNode = (data: any): TreeNode[] => {
    return Object.entries(data).map(([label, value]) => ({
      label,
      key: generateUniqueLabel(),
      children:
        typeof value === "object" && value !== null
          ? createTreeNode(value)
          : undefined,
      data:
        typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "bigint" ||
          value === null ||
          typeof value === "boolean" ||
          typeof value === "symbol"
          ? value
          : undefined,
    }));
  };

  const [editable, setEditable] = useState<string | null>(null);

  const handleBlur = (
    label: string,
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    setEditable(null);
  };

  const [data, setData] = useState<TreeNode[]>([]);

  useEffect(() => {
    if (metadata) {
      setData(createTreeNode(metadata));
    } else {
    }
  }, [metadata]);

  const updateData = (
    key: string,
    newLabel: string,
    nodes: TreeNode[]
  ): TreeNode[] => {
    return nodes.map((node) => {
      if (node.key === key) {
        return { ...node, label: newLabel };
      } else if (node.children) {
        return {
          ...node,
          children: updateData(key, newLabel, node.children),
        };
      } else {
        return node;
      }
    });
  };

  const updateDataChangeValue = (
    key: string,
    newValue: string,
    nodes: TreeNode[],
    type?: string
  ): TreeNode[] => {
    let dataTypeChangedValue: string | boolean | number = newValue;

    if (type === "boolean") {
      dataTypeChangedValue = newValue === "true" ? true : false;
    } else if (type === "number") {
      dataTypeChangedValue = Number(newValue);
    }

    return nodes.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          data: dataTypeChangedValue === false ? false : dataTypeChangedValue,
        };
      } else if (node.children) {
        return {
          ...node,
          children: updateDataChangeValue(key, newValue, node.children, type),
        };
      } else {
        return node;
      }
    });
  };

  function alphaNumericString(length: number) {
    var charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const addConfigData = (
    nodes: TreeNode[],
    configName: string,
    configDataType: string
  ): TreeNode[] => {
    const newNode: TreeNode = {
      label: configName,
      data:
        configDataType === "string"
          ? "Value"
          : configDataType === "number"
            ? 1
            : null,
      key: alphaNumericString(5),
    };
    return [...nodes, newNode];
  };

  const addNewConfig = () => {
    if (configName && configDataType) {
      setData((prevData) => {
        const updatedData = addConfigData(prevData, configName, configDataType);
        return updatedData;
      });
    }
  };

  useEffect(() => {
    callback(addNewConfig);
  }, [callback, configName, configDataType, data]);

  const handleLabelChange = (key: string, newLabel: string) => {
    setData((prevData) => {
      const updatedData = updateData(key, newLabel, prevData);
      return updatedData;
    });
  };

  const handleValueChange = (key: string, newValue: string, type: string) => {
    setData((prevData) => {
      const updatedData = updateDataChangeValue(key, newValue, prevData, type);
      return updatedData;
    });
  };

  const createObjectFromTree = (nodes: TreeNode[]): any => {
    const obj: any = {};
    nodes.forEach((node, index) => {
      if (node.children) {
        obj[node.label] = createObjectFromTree(node.children);
      } else if (
        node.data ||
        typeof node.data === "boolean" ||
        typeof node.data === "number"
      ) {
        obj[node.label] = node.data;
      } else {
        obj[node.label] = null;
      }
    });
    return obj;
  };

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  useEffect(() => {
    const updatedMetadata = createObjectFromTree(data);
    parentCallback(updatedMetadata);
  }, [data]);

  const renderTree = (nodes: TreeNode) => {
    return (
      <StyledTreeItem
        key={nodes.key}
        nodeId={nodes.key}
        label={
          editable === nodes.key ? (
            <input
              className="tree-node-input"
              defaultValue={nodes.label}
              onBlur={(e) => {
                handleBlur(nodes.label, e);
                handleLabelChange(nodes.key, e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleBlur(nodes.label, e);
                  handleLabelChange(
                    nodes.key,
                    (e.target as HTMLInputElement).value
                  );
                }
              }}
            />
          ) : nodes.data !== undefined ? (
            <div className="w-100 admin-strategy-value-container-whole">
              <span className="mr-5">
                {nodes.label}
                <PenIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditable(nodes.key);
                  }}
                />
              </span>
              <div className="admin-strategy-value-container relative">
                {typeof nodes.data === "boolean" ? (
                  <select
                    className="admin-strategy-value"
                    style={width ? { width: width - 35 } : {}}
                    defaultValue={nodes.data.toString()}
                    onBlur={(e) => {
                      handleValueChange(
                        nodes.key,
                        e.target.value,
                        typeof nodes.data
                      );
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      e.currentTarget.blur();
                    }}
                  >
                    <option value={"true"}>True</option>
                    <option value={"false"}>False</option>
                  </select>
                ) : (
                  <input
                    className="admin-strategy-value"
                    style={width ? { width: width - 35 } : {}}
                    defaultValue={nodes.data}
                    onBlur={(e) => {
                      handleValueChange(
                        nodes.key,
                        e.target.value,
                        typeof nodes.data
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.currentTarget.blur();
                      }
                    }}
                  />
                )}

                {/* {typeof nodes.data === "boolean" ? (
                  <select
                    className="admin-strategy-value"
                    style={width ? { width: width - 35 } : {}}
                    defaultValue={nodes.data.toString()}
                  >
                    <option value={"true"}>True</option>
                    <option value={"false"}>False</option>
                  </select>
                ) : (
                  <input
                    className="admin-strategy-value"
                    style={width ? { width: width - 35 } : {}}
                    defaultValue={nodes.data}
                  />
                )} */}
              </div>
            </div>
          ) : (
            <span className="mr-5">
              {nodes.label}
              <PenIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setEditable(nodes.key);
                }}
              />
            </span>
          )
        }
      >
        {nodes.children?.map((node) => renderTree(node))}
      </StyledTreeItem>
    );
  };

  return (
    <Box sx={{ minHeight: 270, flexGrow: 1, maxWidth: "100%" }}>
      <TreeView
        aria-label="customized"
        defaultExpanded={["0"]}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
      >
        {data.map((node) => renderTree(node))}
      </TreeView>
    </Box>
  );
};

export default AdminStrategySettingsData;
