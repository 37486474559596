import React from 'react';
import { Styles, Theme } from '../Utils/Constants';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import youtube from '../assets/images/youtube.png';
import xsocial from '../assets/images/x.png';
import linkedin from '../assets/images/linkedin.png';
import instagram from '../assets/images/instagram.png';
import logo from '../assets/images/logo.svg';
import useWindowSize from './hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const websiteDescription = (
    <div style={{ ...styles.column, ...styles.descriptionText }}>
      <img
        src={logo}
        alt='logo'
        style={
          windowSize <= 540
            ? styles.profileImageStyleResponsive
            : styles.profileImageStyle
        }
      />
      <p
        style={
          windowSize <= 540 ? styles.webpageDescResponsive : styles.webpageDesc
        }
      >
        Automate Investing or Trading with our AI strategies, Backtest Your Idea, and stay informed with real-time market insights.
      </p>
    </div>
  );

  const ContactUsSection = (
    <div
      style={
        windowSize <= 540
          ? { ...styles.column, ...{ fontSize: Theme.fontSizes.h5 } }
          : { ...styles.column }
      }
    >
      <p style={windowSize <= 540 ? {...Styles.h3TextResponsive, margin: 0} : Styles.h3Text}>
        Contact Us
      </p>
      <p style={windowSize <= 540 ? {...styles.boxIcon, marginTop: "11.37px", marginBottom: "0px"} : styles.boxIcon}>
        <EmailIcon
          style={
            windowSize <= 540
              ? { ...styles.icon, ...{ fontSize: '14px', marginRight: '2.7px' } }
              : {...styles.icon, marginRight: '0px'}
          }
        />{' '} &nbsp;
        hi@moneyy.ai
      </p>
      <p style={windowSize <= 540 ? {...styles.boxIcon, marginTop: "8.23px", marginBottom: "0px"} : {...styles.boxIcon, marginRight: '73px'}}>
        <LocationOnIcon
          style={
            windowSize <= 540
              ? { ...styles.icon, ...{ fontSize: '14px', marginRight: '4px' } }
              : styles.icon
          }
        />{' '}&nbsp;
        India
      </p>
    </div>
  );

  const socialListing = (
    <div style={{ ...styles.column, cursor:'pointer' }}>
      <p style={windowSize <= 540 ? Styles.h3TextResponsive : Styles.h3Text}>
        Social
      </p>
      <p style={styles.boxIcon}
      onClick={() => {
        window.open('https://twitter.com/moneyy_ai', '_blank');
      }}>
        <img src={xsocial} style={styles.social} alt='x' />
      </p>
      <p style={styles.boxIcon}
      onClick={() => {
        window.open('https://www.instagram.com/_moneyy.ai/', '_blank');
      }}>
        <img src={instagram} style={styles.social} alt='instagram' />
      </p>
      <p style={styles.boxIcon}
      onClick={() => {
        window.open('https://www.linkedin.com/company/moneyy-ai/', '_blank');
      }}>
        <img src={linkedin} style={styles.social} alt='linkedin' />
      </p>
      <p style={styles.boxIcon}
      onClick={() => {
        window.open('https://www.youtube.com/@ManiMoneyy?sub_confirmation=1 ', '_blank');
      }}>
        <img src={youtube} style={styles.social} alt='youtube' />
      </p>
    </div>
  );

  const sections = [websiteDescription, ContactUsSection, socialListing]; // List of active sections

  return (
    <footer
      className='footer-special'
      style={windowSize <= 540 ? styles.containerResponsive : styles.container}
    >
      <div style={styles.footerHeader}>
        <p
          style={
            windowSize <= 540 ? styles.pageTitleResponsive : styles.pageTitle
          }
        >
          Unanswered Queries?
        </p>
        <p
          style={
            windowSize <= 540
              ? styles.contactMailResponsive
              : styles.contactMail
          }
        >
          Reach us out at hi@moneyy.ai
        </p>
      </div>
      <div
        style={{
          ...styles.footerColumns,
          gridTemplateColumns: `repeat(${sections.length}, 1fr)`,
        }}
      >
        {sections.map((section, index) => (
          <React.Fragment key={index}>{section}</React.Fragment>
        ))}
      </div>
      <div
        style={
          windowSize <= 540
            ? styles.footerCopyrightResponsive
            : styles.footerCopyright
        }
      >
        <p>&copy; 2023 Moneyy.ai Pvt Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

const styles = {
  pageTitle: {
    fontSize: Theme.fontSizes.h1,
    margin: 0,
  },
  pageTitleResponsive: {
    color: Theme.colors.grey100,
    fontWeight: Theme.fontWeight.semiBold,
    lineHeight: 'normal',
    letterSpacing: '1.08px',
    fontSize: Theme.fontSizes.p18,
    margin: 0,
  },
  container: {
    paddingLeft: Theme.gapXXLarge,
    paddingRight: Theme.gapXXLarge,
    marginTop: Theme.gapLarge,
  },
  containerResponsive: {
    marginTop: Theme.gapLarge,
  },
  webpageDesc: {
    fontSize: Theme.fontSizes.h6,
  },
  webpageDescResponsive: {
    fontSize: Theme.fontSizes.h5,
  },
  boxIcon: {
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
  },
  icon: {
    color: Theme.colors.blueSolid,
  },
  descriptionText: {
    paddingRight: Theme.gapSmall,
  },
  profileImageStyle: {
    width: Theme.iconWidth,
  },
  profileImageStyleResponsive: {
    height: Theme.footerIconHeight,
  },
  footerHeader: {
    paddingBottom: Theme.gapSmall,
    textAlign: 'left' as const,
    borderBottom: `1px solid ${Theme.colors.whiteGrey70}`,
  },
  footerColumns: {
    display: 'grid' as const,
    columnGap: '100px',
    rowGap: '30px',
    justifyContent: 'space-around',
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
  },
  footerColumnsResponsive: {
    display: 'grid' as const,
    gridTemplateColumns: '1fr',
    columnGap: '0px',
    rowGap: '30px',
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
  },
  footerTwoCol: {
    display: 'grid' as const,
    gridTemplateColumns: '1fr 1fr',
    columnGap: '0px',
    rowGap: '30px',
    paddingTop: Theme.gapLarge,
    paddingBottom: Theme.gapLarge,
  },
  column: {
    width: 'auto',
    minWidth: 150,
  },
  footerCopyright: {
    padding: Theme.gapSmall,
    textAlign: 'left' as const,
    fontSize: Theme.fontSizes.h6,
    borderTop: `1px solid ${Theme.colors.whiteGrey70}`,
  },
  footerCopyrightResponsive: {
    padding: Theme.gapSmall,
    textAlign: 'left' as const,
    fontSize: Theme.fontSizes.p11,
    borderTop: `1px solid ${Theme.colors.whiteGrey70}`,
  },
  contactMailResponsive: {
    color: Theme.colors.black70,
    fontSize: Theme.fontSizes.h5,
    fontWeight: Theme.fontWeight.regular,
    marginTop: Theme.gap5,
  },
  contactMail: {
    fontSize: Theme.fontSizes.h4,
    fontWeight: Theme.fontWeight.light,
    margin: 0,
  },
  social: {
    width: 20,
  },
};

export default Footer;
