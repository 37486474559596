import React from 'react';
import expimg from '../../../../assets/images/expss.png';
import headss from '../../../../assets/images/headgess.png';
import stratss from '../../../../assets/images/strategyss.png';
import headge from '../../../../assets/images/headgess.png';
import EditIcon from '@mui/icons-material/Edit';

interface DataItem {
  [key: string]: string;
}

const table1Data: DataItem[] = [
  { 'Intraday Non Expiry': '9:30:00 AM' },
  { 'Intraday Expiry': '9:30:00 AM' },
  { 'Overnight Strangle': 'False' },
  { 'Overnight Trade': 'True' },
  { 'BANKNIFTY Max Lots': '60' },
  { 'NIFTY Max Lots': '36' },
  { 'Kill Switch': 'FALSE' },
  { 'MIDCPNIFTY Max Lots': '56' },
  { 'FINNIFTY Max Lots': '45' },
  { 'BANKEX Max Lots': '40' },
  { 'SENSEX Max Lots': '100' },
];
const table2Data: DataItem[] = [
  { 'Algo Exit Time': '9:30:00 AM' },
  { 'Intraday Last Entry Non expiry': '9:30:00 AM' },
  { 'Intraday Last Entry Expiry': '3:03:00 PM' },
  { 'Strike Sustain Minute': '1' },
  { 'Straddle Profit Pcnt': '1' },
  { 'Starddle Day Max Loss': '-2' },
  { minium_margin: '30000' },
  { 'straddle Max Loss X': '56' },
  { 'Sleep on Straddle Max Loss X': '45' },
  { 'Rangex Day Max Loss': '40' },
];

const expiryFactorTable: DataItem[] = [
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
];
const hedgesFactorTable: DataItem[] = [
  {
    Hedges: 'TRUE',
    Expiry: '0.035',
    'Expiry-1': '0.03',
    'Expiry- 2': '0.04',
    'Expiry-3': '0.05',
    'Expiry-4': '0.06',
    'LTP Expiry': '0.2',
    'LTP Non Expiry': '1.5',
  },
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
  {
    Startegy: 'Expiry',
    MidCpNifty: 'TRUE',
    FinNifty: 'TRUE',
    BankNifty: 'FALSE',
    Nifty: 'TRUE',
    Sensex: 'TRUE',
    Bankex: 'FALSE',
  },
];

const strategyKeyParameterTable2: DataItem[] = [
  {
    'Exit & Entry Condition': 'Breakout',
    'Entry Time': '9:30:00 AM',
    'Exit Time': '10:30:00 AM',
  },
  {
    'Exit & Entry Condition': '1% Saddle loss',
    'Entry Time': '10:30:00 AM',
    'Exit Time': '11:30:00 AM',
  },
  {
    'Exit & Entry Condition': '1% Saddle Profit',
    'Entry Time': '9:30:00 AM',
    'Exit Time': '10:30:00 AM',
  },
  {
    'Exit & Entry Condition': 'Day Max Loss',
    'Entry Time': '10:30:00 AM',
    'Exit Time': '11:30:00 AM',
  },
  {
    'Exit & Entry Condition': '80% Target Hit',
    'Entry Time': '9:30:00 AM',
    'Exit Time': '10:30:00 AM',
  },
  {
    'Exit & Entry Condition': 'LTP 2x',
    'Entry Time': '10:30:00 AM',
    'Exit Time': '11:30:00 AM',
  },
  {
    'Exit & Entry Condition': 'PCR Range breakout',
    'Entry Time': '9:30:00 AM',
    'Exit Time': '10:30:00 AM',
  },
  {
    'Exit & Entry Condition': 'PCR New strike found',
    'Entry Time': '10:30:00 AM',
    'Exit Time': '11:30:00 AM',
  },
  {
    'Exit & Entry Condition': 'CPR Range Breakout',
    'Entry Time': '9:30:00 AM',
    'Exit Time': '10:30:00 AM',
  },
  {
    'Exit & Entry Condition': 'CPR New Strike Found',
    'Entry Time': '10:30:00 AM',
    'Exit Time': '11:30:00 AM',
  },
  {
    'Exit & Entry Condition': 'VIX',
    'Entry Time': '10:30:00 AM',
    'Exit Time': '11:30:00 AM',
  },
];

const headingTable: DataItem[] = [
  {
    'PCR & Stradle Max Loss': 'Midcp Nifty',
    'Min Breakout Range': '0.5',
    'In Range A': '0.75',
    'In Range B': '1.25',
    'Max Breakout Range': '1.5',
    'Stradle Max Loss in Range': '-0.5',
    'Stradle Max Loss in Breakout Range': '-0.3',
    'VIX %': '2',
  },
  {
    'PCR & Stradle Max Loss': 'Midcp Nifty',
    'Min Breakout Range': '0.5',
    'In Range A': '0.75',
    'In Range B': '1.25',
    'Max Breakout Range': '1.5',
    'Stradle Max Loss in Range': '-0.5',
    'Stradle Max Loss in Breakout Range': '-0.3',
    'VIX %': '2',
  },
  {
    'PCR & Stradle Max Loss': 'Midcp Nifty',
    'Min Breakout Range': '0.5',
    'In Range A': '0.75',
    'In Range B': '1.25',
    'Max Breakout Range': '1.5',
    'Stradle Max Loss in Range': '-0.5',
    'Stradle Max Loss in Breakout Range': '-0.3',
    'VIX %': '2',
  },
  {
    'PCR & Stradle Max Loss': 'Midcp Nifty',
    'Min Breakout Range': '0.5',
    'In Range A': '0.75',
    'In Range B': '1.25',
    'Max Breakout Range': '1.5',
    'Stradle Max Loss in Range': '-0.5',
    'Stradle Max Loss in Breakout Range': '-0.3',
    'VIX %': '2',
  },
  {
    'PCR & Stradle Max Loss': 'Midcp Nifty',
    'Min Breakout Range': '0.5',
    'In Range A': '0.75',
    'In Range B': '1.25',
    'Max Breakout Range': '1.5',
    'Stradle Max Loss in Range': '-0.5',
    'Stradle Max Loss in Breakout Range': '-0.3',
    'VIX %': '2',
  },
  {
    'PCR & Stradle Max Loss': 'Midcp Nifty',
    'Min Breakout Range': '0.5',
    'In Range A': '0.75',
    'In Range B': '1.25',
    'Max Breakout Range': '1.5',
    'Stradle Max Loss in Range': '-0.5',
    'Stradle Max Loss in Breakout Range': '-0.3',
    'VIX %': '2',
  },
];
// StrategyTable: React.FC =
const StrategySettings: React.FC = () => {
  return (
    <div>
      {/* StrategySettings */}
      <div className='strategy-key-paramters-container'>
        <div className='strategy-key-paramters-heading'>
          <div className='underline-container'>
            <span className='underline-text'> Strategy Key Parameters</span>
            <span className='circle' />
          </div>
          <EditIcon />
        </div>
        <div className='strategy-key-paramters-tables'>
          <div className='strategy-key-paramters-table1'>
            <table>
              <thead>
                <tr>
                  <th>Strategy</th>
                  <th>Entry Time</th>
                </tr>
                <div className='underline'></div>
              </thead>
              <tbody>
                {table1Data.map((item, index) => (
                  <tr key={index}>
                    {Object.entries(item).map(([strategy, entryTime]) => (
                      <React.Fragment key={strategy}>
                        <td>{strategy}</td>
                        <td>{entryTime}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='strategy-key-paramters-table2'>
            <table>
              <thead>
                <tr>
                  <th>Strategy</th>
                  <th>Entry Time</th>
                </tr>
                <div className='underline'></div>
              </thead>
              <tbody>
                {table2Data.map((item, index) => (
                  <tr key={index}>
                    {Object.entries(item).map(([strategy, entryTime]) => (
                      <React.Fragment key={strategy}>
                        <td>{strategy}</td>
                        <td>{entryTime}</td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Expiry Factors */}
      <div className='expiry-factors-container'>
        <div className='strategy-key-paramters-heading'>
          <div className='underline-container'>
            <span className='underline-text'> Expiry Factors</span>
            <span className='circle' />
          </div>
          <EditIcon />
        </div>
        <div className='expiry-factor-table'>
          <table>
            <thead>
              <tr>
                <th style={{ width: '6%' }} className='empty-table-head'></th>
                {Object.keys(expiryFactorTable[0]).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </tr>
            </thead>
            <div className='underline'></div>
            <tbody>
              {expiryFactorTable.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: '7%' }}>
                    {index > 0 ? `Expiry-${index}` : `Expiry ${' '}  `}
                  </td>
                  {Object.values(item).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Hedges Factors */}
      <div className='hedges-factors-container'>
        <div className='strategy-key-paramters-heading'>
          <div className='underline-container'>
            <span className='underline-text'> Hedges Factors</span>
            <span className='circle' />
          </div>
          <EditIcon />
        </div>
        <div style={{ overflowX: 'auto' }} className='expiry-factor-table'>
          <table>
            <thead>
              <tr>
                <th style={{ width: '8%' }} className='empty-table-head'></th>
                {Object.keys(hedgesFactorTable[0]).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </tr>
              <div className='underline'></div>
            </thead>
            <tbody>
              {hedgesFactorTable.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: '10%' }}>
                    {index > 0 ? `Expiry-${index}` : ` Expiry ${' '}  `}
                  </td>
                  {Object.values(item).map((value, index) => (
                    <td style={{ width: '10%',border:"solid" }} key={index}>
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Startegy Gain Parameters */}
      <div className='strategy-key-paramters-container'>
        <div className='strategy-key-paramters-heading'>
          <div className='underline-container'>
            <span className='underline-text'> Strategy Key Parameters</span>
            <span className='circle' />
          </div>
          <EditIcon />
        </div>
        <div className='strategy-key-paramters-table'>
          <table style={{ width: '500px' }}>
            <thead>
              <tr style={{}}>
                <th style={{ width: '50%' }}>Exit & Entry Condition</th>
                <th>Entry Time</th>
                <th>Exit Time</th>
              </tr>
            </thead>
            <div className='underline'></div>
            <tbody>
              {strategyKeyParameterTable2.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: '53%' }}>
                    {item['Exit & Entry Condition']}
                  </td>
                  <td>{item['Entry Time']}</td>
                  <td>{item['Exit Time']}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Heading */}
      <div className='heading-section-table-container'>
        <div className='strategy-key-paramters-heading'>
          <div className='underline-container'>
            <span className='underline-text'> Heading</span>
            <span className='circle' />
          </div>
          <EditIcon />
        </div>
        <div style={{ overflowX: 'auto' }} className='heading-section-table'>
          <table style={{}}>
            <thead>
              <tr style={{}}>
                <th style={{ width: '65%' }}>PCR & Stradle Max Loss</th>
                <th>Min Breakout Range</th>
                <th>In Range A</th>
                <th>In Range B</th>
                <th>Max Breakout Range</th>
                <th style={{ width: '75%' }}>Stradle Max Loss in Range</th>
                <th>Stradle Max Loss in Breakout Range</th>
                <th>VIX %</th>
              </tr>
              <div className='underline'></div>
            </thead>
            <tbody>
              {headingTable.map((item, index) => (
                <tr key={index}>
                  <td style={{}}>{item['PCR & Stradle Max Loss']}</td>
                  <td>{item['Min Breakout Range']}</td>
                  <td>{item['In Range A']}</td>
                  <td>{item['In Range B']}</td>
                  <td>{item['Max Breakout Range']}</td>
                  <td>{item['Stradle Max Loss in Range']}</td>
                  <td>{item['Stradle Max Loss in Breakout Range']}</td>
                  <td>{item['VIX %']}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StrategySettings;
