import { Route, Routes } from "react-router-dom";
import "./App.css";
import AdminCreateStrategy from "./components/Admin/AdminCreateStrategy/AdminCreateStrategy";
import AdminContentEditingPage from "./components/Admin/ContentEditing/AdminContentEditingPage";
import KillSwitchPage from "./components/Admin/KillSwitch/KillSwitchPage";
import LoginPage from "./components/Auth/Login";
import ImageTesting from "./API/imagetesting";
import AdminPage from "./components/Admin/AdminPage";
import React from "react";
  
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/admin" element={<AdminPage />} />

        <Route
          path="/admin-create-strategy"
          element={<AdminCreateStrategy />}
        />
        <Route path="/edit/:strategyId" element={<AdminContentEditingPage />} />
        <Route path="/kill-switch/:strategyId" element={<KillSwitchPage />} />
        {/* <Route path='/testing' element={<ImageTesting />} /> */}
      </Routes>
    </div>
  );
}

export default App;
