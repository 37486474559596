import React from "react";
import { Theme } from "../../../Utils/Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface RowProps {
    userDetails: PositionDetails;
    index: number;
}

interface PositionDetails {
    name: string;
    strategy_id: string;
    total_capital_deployed: number;
    total_pnl: number;
    total_pnl_pct: number;
    userid: number;
}

const CustomerHistoricTable: React.FC<RowProps> = ({
    userDetails,
    index
}) => {

    const formatNumber = (value: number) => {
        if (value >= 10000000) {
            return `${(value / 10000000).toFixed(1)} Cr`;
        } else if (value >= 100000) {
            return `${(value / 100000).toFixed(1)} L`;
        } else {
            const formattedValue = value?.toString();
            const decimalIndex = formattedValue.indexOf('.');
            if (decimalIndex !== -1) {
                const fractionalPart = formattedValue.slice(decimalIndex + 1);
                if (/^0+$/.test(fractionalPart)) {
                    return formattedValue.slice(0, decimalIndex); // Remove trailing zeros after decimal
                }
            }
            return formattedValue;
        }
    };

    return (
        //want to add toastcontainer
        <>
            <ToastContainer />
            <tr key={index + 1} className="" style={styles.tableRow}>
                <td className="" style={{ ...styles.tableData, textAlign: "center" }}>
                    {/* {index + 1} */}
                    {userDetails.userid}
                </td>
                <td
                    style={{ ...styles.tableData, textAlign: "center" }}
                    className="flex flex-center"
                >
                    {userDetails.name}
                </td>
                <td
                    className=""
                    style={{ ...styles.tableData, textAlign: "center" }}
                >
                    <div style={
                        userDetails.total_capital_deployed < 0
                            ? { color: Theme.colors.red }
                            : { color: Theme.colors.green }}>
                        {formatNumber(userDetails.total_capital_deployed)}
                    </div>
                </td>
                <td
                    className=""
                    style={{ ...styles.tableData, textAlign: "center" }}
                >
                    <div style={
                        userDetails.total_pnl < 0
                            ? { color: Theme.colors.red }
                            : { color: Theme.colors.green }}>
                        {formatNumber((userDetails.total_pnl))}
                    </div>
                </td>
                <td
                    className=""
                    style={{ ...styles.tableData, textAlign: "center" }}
                >
                    <div style={
                        userDetails.total_pnl_pct < 0
                            ? { color: Theme.colors.red }
                            : { color: Theme.colors.green }}>
                        {formatNumber((userDetails.total_pnl_pct)) + ' %'}
                    </div>
                </td>

            </tr>
        </>
    );
};

const styles = {
    tableRow: {
        fontWeight: "400",
        color: "rgba(0, 0, 0, 0.7)",
        lineHeight: "26px",
        fontSize: "16px",
    },
    tableData: {
        textAlign: "center",
        paddingTop: "13px",
        paddingBottom: "13px",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "0.96px",
    }
};

export default CustomerHistoricTable;
