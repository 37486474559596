import React, { useEffect, useState } from "react";
// import './adminCreateStrategy.css';
import Header, { CurrentPage } from "../../Header/Header";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DoneIcon from "@mui/icons-material/Done";
import AdminContentEditing from "./AdminContentEditing";
import { useNavigate, useParams } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AdminStrategySettings from "./AdminStrategySettings";
import { checkIsAdminLoggedIn } from "../../../API/Auth";
interface EditPageParams {
  strategyId?: number;
}

const AdminContentEditingPage: React.FC = () => {
  // const { strategyId } = useParams<EditPageParams>();
  const { strategyId } = useParams() as EditPageParams;

  const [showImage, setShowImage] = useState(false);
  const [metaData, setMetaData] = useState(Object);

  const handleCallback = (childData: string) => {
    setMetaData(childData);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      const isLoggedIn = await checkIsAdminLoggedIn();
      if (isLoggedIn.loggedIn) {

      }else{
        navigate("/");
      }
    };

    checkAdminStatus();
  }, []);

  useEffect(() => {
    // console.log(metaData);
  }, [metaData]);

  const handleClick = () => {
    setShowImage(!showImage);
  };

  const [currentTab, setCurrentTab] = useState(0);

  const updateMetadata = async () => {
    const apiUrl = process.env.REACT_APP_BASE_URL as string + 'metadata/update';
    try {
      const response = await fetch(
        apiUrl,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            strategyID: strategyId,
            new_metadata: metaData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching strategy details:", error);
    }
  };

  return (
    <div className="">
      <Header currentPage={CurrentPage.AI_Strategies} />
      {showImage && (
        <div
          style={{
            position: "fixed",
            border: "solid",
            top: "50%",
            left: "50%",
            height: "50px",
            width: "10%",
            objectFit: "contain",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* <img
            src={dialogss} 
            alt='Centered Imagez'
          /> */}
        </div>
      )}
      <div className="admin-content-strategy-container">
        <div style={{ width: "20%" }} className="admin-navbar">
          <NavBar />
        </div>
        <div className="admin-create-startegy-landing-page">
          <div className="admin-create-strategy-tab-container">
            <div className="admin-create-strategy-tabs">
              <h5
                className={currentTab === 0 ? "active" : ""}
                onClick={() => {
                  setCurrentTab(0);
                }}
              >
                Content Editing 
              </h5>
              <h5
                className={currentTab === 1 ? "active" : ""}
                onClick={() => {
                  setCurrentTab(1);
                }}
              >
                Strategy Settings
              </h5>
            </div>
            <div className="admin-header-buttons">
              <div onClick={handleClick} className="admin-save-button">
                <DeleteForeverIcon /> Discard Changes
              </div>

              <div
                className="admin-add-button pointer"
                onClick={() => {
                  if (currentTab === 1) {
                    if (
                      window.confirm("Are you sure editing Strategy Settings?")
                    ) {
                      updateMetadata();
                    }
                  }
                }}
              >
                <DoneIcon /> Edit
              </div>
            </div>
          </div>

          <div className="admin-create-strategy-tab-content">
            <p
              style={
                currentTab === 0
                  ? {
                      display: "block",
                      flex: 1,
                      height: "100%",
                      width: "100%",
                      margin: 0,
                      padding: 0,
                    }
                  : { display: "none" }
              }
            >
              <AdminContentEditing strategyId={strategyId} />
            </p>
            <p
              style={
                currentTab === 1
                  ? {
                      display: "block",
                      flex: 1,
                      height: "100%",
                      width: "100%",
                      margin: 0,
                      padding: 0,
                      paddingRight: 44,
                      paddingTop: 18,
                      boxSizing: "border-box",
                    }
                  : { display: "none" }
              }
            >
              <AdminStrategySettings
                strategyId={strategyId}
                metaCallBack={handleCallback}
              />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminContentEditingPage;
