import React, { useEffect, useState } from "react";
import { Theme, Styles } from "../../../Utils/Constants";
import "./adminCreateStrategy.css";
import Header, { CurrentPage } from "../../Header/Header";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DoneIcon from "@mui/icons-material/Done";
import AdminCreateContent from "./TabsContent/AdminCreateContent";
import GlobalSetting from "./TabsContent/GlobalSetting";
import imagz from "../../../assets/images/ss-for-page.png";
import StrategySettings from "./TabsContent/StrategySettings";
import Loader from "../../Dialogs/Loader";
import { useNavigate } from "react-router-dom";
import { checkIsAdminLoggedIn } from "../../../API/Auth";
const AdminCreateStrategy = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      const isLoggedIn = await checkIsAdminLoggedIn();
      if (isLoggedIn.loggedIn) {

      }else{
        navigate("/");
      }
    };

    checkAdminStatus();
  }, []);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
    setIsLoading(false);
  };

  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <div className="">
      <Header currentPage={CurrentPage.AI_Strategies} />
      <div className="admin-create-strategy-container">
        <div style={{ width: "20%" }} className="admin-navbar">
          <NavBar />
        </div>
        <div className="admin-create-startegy-landing-page">
          <div className="admin-create-strategy-tab-container">
            <h5>Create Strategy</h5>
            <div className="tabs-container">
              <div
                className={`tab ${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                <span className="number-icon">1</span>
                Content Editing
              </div>
              <div
                className={`tab ${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabClick(2)}
              >
                <span className="number-icon">2</span> Startegy Settings
              </div>
              <div
                className={`tab ${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabClick(3)}
              >
                <span className="number-icon">3</span> Global Settings
              </div>
            </div>
            <div className="admin-save-button">
              <BookmarkIcon /> Save
            </div>
            <div className="admin-add-button">
              <DoneIcon /> Add
            </div>
          </div>

          <div className="admin-create-strategy-tab-content">
            {activeTab === 1 && (
              <p>
                <AdminCreateContent />
              </p>
            )}
            {activeTab === 2 && (
              <p>
                {/* <img src={imagz} alt='' /> */}
                <StrategySettings />
              </p>
            )}
            {activeTab === 3 && (
              <p>
                <GlobalSetting />
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminCreateStrategy;
