import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Theme } from "../../../Utils/Constants";
import CustomTabRow from "./CustomerTableRow";
import useOutSideClick from "../../hooks/useOutSide";
import {
  UserDetailsByStartegyNameApiResponse,
  getUserDetailsByStrategyName,
} from "../../../API/AdminPageAPI";
import useAppStore from "../../../store/app.store";
import { PositionDetails } from "./CustomerTableAPI";
import Loader from "../../Dialogs/Loader";
import CustomerHistoricTable from "./CustomerHistoricTable";

interface CustomTabContentProps {
  strategyid?: string;
}

const CustomerHistoricalTab: React.FC<CustomTabContentProps> = ({
  strategyid,
}) => {
  const strategyContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<PositionDetails[]>([]);

  interface PositionDetails {
    name: string;
    strategy_id: string;
    total_capital_deployed: number;
    total_pnl: number;
    total_pnl_pct: number;
    userid: number;
  }


  const fetchData = async () => {
    const url = process.env.REACT_APP_BASE_URL as string + 'user-metrics';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY as string,
      },
      body: JSON.stringify({ strategyId: 'strat_0008' }),
    });
    if (!response.ok) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
    setUserDetails(responseData.response_data);
    // console.log(responseData.response_data);
    if (responseData.response_data.length !== 0) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderCustomerData = () => {
    return userDetails.length > 0 ? (
      userDetails.map((data, index) => (
        <CustomerHistoricTable
          userDetails={data}
          index={index}
        />
      ))
    ) : (
      <div className="flex flex-center" style={{ padding: "20px" }}>
        No data found
      </div>
    );
  };


  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
        <div
          style={{ ...styles.fullPageContainer, overflowX: "auto" }}
          className="kill-switch-content"
        >
          <div style={styles.tableContainer}>
            <table style={styles.table}>
              <thead style={styles.tableHeading}>
                <tr style={styles.tableNav}>
                  <th style={styles.tabelNavItems}>User ID</th>
                  <th style={styles.tabelNavItems}>User Name</th>
                  {/* <th style={styles.tabelNavItems}>Restart</th> */}
                  <th style={styles.tabelNavItems}>Total Capital</th>
                  <th style={styles.tabelNavItems}>Total PnL</th>
                  <th style={styles.tabelNavItems}>Pnl Percentage</th>
                </tr>
              </thead>
              <tbody style={styles.tbody}>
              {renderCustomerData()}
              </tbody>
            </table>
          </div>
        </div>
      )}
        </>
     
  );
};

const styles = {
  fullPageContainer: {
    padding: Theme.gapSmall,
    width: "100%",
    height: "100%",
  },
  selectStrategyContainer: {
    display: "flex",
    justifyContent: "flex-start",
    // width: '100%',
    paddingBottom: "29px",
  },
  sortByOption: {
    position: "absolute" as const,
    zIndex: "999",
    top: "110%",
    backgroundColor: "#ffffff",
    marginLeft: "15%",
    width: "105%",
    borderRadius: "3px",
    // overflow: 'hidden',
    padding: "10px 13px",
    paddingRight: "0px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    gap: "13px",
    border: "0.5px solid #000000b2",
    boxShadow: "1px 1px 10px 0px #00000040",
  },
  sortOption: {
    background: "#ffffff",
    lineHeight: "21px",
    letterSpacing: "0.03em",
    textAlign: "left" as const,
    margin: "0",
    // borderRadius: '0',
    border: "none",
    padding: "0px",
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "500",
  },
  selectStrategy: {
    display: "flex",
    width: "auto",
    alignItems: "center",
    justifyContent: "center",
    position: "relative" as const,
    cursor: "pointer",
  },
  span: {
    display: "flex",
    minWidth: "90%",
    textDecoration: "none",
    border: "solid 0.5px #B2B2B2B2",
    height: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    // gap: '9px',
    marginLeft: "15%",
  },

  tableContainer: {
    width: "100%",
    overflowX: "auto" as const,
  },
  table: {
    minWidth: "100%",
    borderRadius: "3px",
    borderSpacing: "0px",
    borderCollapse: "collapse" as const,
    padding: "0",
    marginTop: "0px",
    marginBottom: "94px",
    whiteSpace: "nowrap",
    backgroundColor: "#FFFFFF,",
  },
  tbody: {
    border: `solid ${Theme.colors.whitishGrey} `,
  },
  tableHeading: {
    fontFamily: "poppins",
    background: "#f3f6ff",
    height: "51px",
    borderRadius: "3px",
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  tableNav: {
    color: "#000",
    fontSize: "14px",
    letterSpacing: "0.84px",
    width: "100%",
  },
  tabelNavItems: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "21px",
    letterSpacing: "0.06em",
    textAlign: "center" as const,
    padding: "0 10px",
    gap: "10px",
  },
  topRow: {
    borderBottom: "solid",
    height: "45px",
    color: Theme.colors.whiteGrey,
    marginLeft: "30px",
  },
  noDataFound: {
    textAlign: "center" as const,
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0.06em",
    padding: "20px 10px",
  },
};

export default CustomerHistoricalTab;
