import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Theme } from "../../../Utils/Constants";
import CustomTabRow from "./CustomerTableRow";
import useOutSideClick from "../../hooks/useOutSide";
import {
  UserDetailsByStartegyNameApiResponse,
  getUserDetailsByStrategyName,
} from "../../../API/AdminPageAPI";
import useAppStore from "../../../store/app.store";
import { PositionDetails } from "./CustomerTableAPI";
import { fetchStrategyDetails } from "../../../Utils/FetchStrategyDetails";

interface CustomTabContentProps {
  strategyid?: string;
}

const CustomerTabContent: React.FC<CustomTabContentProps> = ({
  strategyid,
}) => {
  const strategyContainerRef = useRef(null);
  const [selectedStrategy, setSelectedStrategy] = useState("Select Strategy");

  const [isStrategyDropDownOpen, setIsStrategyDropDownOpen] = useState(false);
  const [userDetailsByStrategyName, setUserDetailsByStrategyName] = useState<
    UserDetailsByStartegyNameApiResponse[]
  >([]);

  const [strategyDetails, setStrategyDetails] = useState<any>({});

  const TotalStrategyPnl: number[] = [];

  // const [algoPositionDetailsForAllUsers, setAlgoPositionDetailsForAllUsers] = useState<{ [userID: string]: { [key: string]: PositionDetails[]; }; }>({});
  const [algoPositionDetailsForAllUsers, setAlgoPositionDetailsForAllUsers] =
    useState<{
      [userID: string]: {
        [key: string]: PositionDetails[];
      };
    }>({});

  interface TotalStrategyPnlUser {
    userid: number;
    totalsum: number;
  }

  const TotalStrategyPnlForAllUsers: TotalStrategyPnlUser[] = [];

  interface LtpData {
    [position: string]: {
      last_price: number;
    };
  }

  const [ltpsData, setLTPSData] = useState<LtpData>({});

  const { adminTableData } = useAppStore();

  // const fetchData = async () => {
  //   try {
  //     const details = await fetchStrategyDetails(String(strategyid));

  //     if (details) {
  //       setStrategyDetails(details);
  //     } else {
  //       console.error('Failed to fetch strategy details.');
  //     }
  //   } catch (error) {
  //     console.error('Error in fetchData:', error);
  //   }
  // };

  // useEffect(() => {

  //   fetchData();
  // }, [strategyid]);

  useOutSideClick(strategyContainerRef, () => {
    setIsStrategyDropDownOpen(false);
  });

  const handleStrategyDropDown = () => {
    setIsStrategyDropDownOpen(!isStrategyDropDownOpen);
  };
  const handleSelectedStrategy = (option: any) => {
    setAlgoPositionDetailsForAllUsers({});
    setSelectedStrategy(option);
    handleStrategyDropDown();
  };

  const renderCustomerData = () => {
    return userDetailsByStrategyName.length > 0 ? (
      userDetailsByStrategyName.map((data, index) => (
        <CustomTabRow
          userDetails={data}
          index={index}
          capitalDeployed={strategyDetails?.minimum_investment_capital}
          TotalStrategyPnlForAllUsers={TotalStrategyPnlForAllUsers}
          setAlgoPositionDetailsForAllUsers={setAlgoPositionDetailsForAllUsers}
          algoPositionDetailsForAllUsers={algoPositionDetailsForAllUsers}
        />
      ))
    ) : (
      <div className="flex flex-center" style={{ padding: "20px" }}>
        No data found
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        getUserDetailsByStrategyName(selectedStrategy).then((data) =>
          setUserDetailsByStrategyName(data)
        );

        // setUserDetailsByStrategyName(response.response_data);
      } catch (error) {
        console.error("Error fetching user strategy details:", error);
      }
    };

    fetchData();
  }, [selectedStrategy]);

  return (
    <div
      style={{ ...styles.fullPageContainer, overflowX: "auto" }}
      className="kill-switch-content"
    >
      <div style={styles.selectStrategyContainer} className="">
        <div
          style={{
            ...styles.selectStrategy,
            width: "203.65px",
            minWidth: "203.65px",
          }}
          ref={strategyContainerRef}
        >
          {/* <label style={styles.label}>Strategy</label> */}
          <span
            style={{ ...styles.span, padding: "0px 17.65px 0px 25px" }}
            onClick={handleStrategyDropDown}
          >
            {selectedStrategy}
            {isStrategyDropDownOpen ? (
              <>
                <KeyboardArrowUpIcon />
              </>
            ) : (
              <>
                <KeyboardArrowDownIcon />
              </>
            )}
          </span>
          {isStrategyDropDownOpen && (
            <div className="flex flex-center" style={styles.sortByOption}>
              {adminTableData.map((option, index) => (
                <button
                  key={option.id}
                  // className='sort-option'
                  style={{ ...styles.sortOption }}
                  onClick={() => handleSelectedStrategy(option.strategy_name)}
                >
                  {option.strategy_name}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div style={styles.tableContainer}>
        <table style={styles.table}>
          <thead style={styles.tableHeading}>
            <tr style={styles.tableNav}>
              <th style={styles.tabelNavItems}>User Name</th>
              <th style={styles.tabelNavItems}>Kill Switch</th>
              {/* <th style={styles.tabelNavItems}>Restart</th> */}
              <th style={styles.tabelNavItems}>Status</th>
              <th style={styles.tabelNavItems}>Lots Account</th>
              <th style={styles.tabelNavItems}>Total Live Positions</th>
              <th style={styles.tabelNavItems}>Total Closed Positions</th>
              <th style={styles.tabelNavItems}>Current P&L % </th>
              <th style={styles.tabelNavItems}>Last Order Time</th>
              <th style={styles.tabelNavItems}>Last Order Status</th>
              <th style={styles.tabelNavItems}>Rejections</th>

              {/* <th style={styles.tabelNavItems}>Subscription Status</th> */}
              <th style={styles.tabelNavItems}>Variance in P&L %</th>
              <th style={styles.tabelNavItems}>Variance in Last Order Time</th>
            </tr>
          </thead>

          <tbody style={styles.tbody}>
            {/* <tr
              key={0}
              className=''
              style={{ ...styles.topRow, marginLeft: '50px' }}
            >
              <td className='' style={{ textAlign: 'center' }}>
                count
              </td>
              <td className=' ' style={{}}>
                8
              </td>
              <td className=' bluecolortable' style={{ textAlign: 'center' }}>
                8
              </td>
              <td style={{}} className=''>
                10
              </td>
              <td
                className=''
                style={{
                  textAlign: 'center',
                  // color:
                  //   Number(data.strategy1) > 0 ? ' #2ACD1C' : '#F82929',
                }}
              >
                10
              </td>
              <td style={{}}>10</td>
              <td style={{}}>9</td>
              <td style={{}}>10</td>
              <td style={{}}> 9</td>
              <td style={{}}>10</td>
              <td style={{}}>9</td>
              <td style={{}}>10</td>
            </tr> */}

            {/*  */}
            {renderCustomerData()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  fullPageContainer: {
    padding: Theme.gapSmall,
    width: "100%",
    height: "100%",
  },
  selectStrategyContainer: {
    display: "flex",
    justifyContent: "flex-start",
    // width: '100%',
    paddingBottom: "29px",
  },
  sortByOption: {
    position: "absolute" as const,
    zIndex: "999",
    top: "110%",
    backgroundColor: "#ffffff",
    marginLeft: "15%",
    width: "105%",
    borderRadius: "3px",
    // overflow: 'hidden',
    padding: "10px 13px",
    paddingRight: "0px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    gap: "13px",
    border: "0.5px solid #000000b2",
    boxShadow: "1px 1px 10px 0px #00000040",
  },
  sortOption: {
    background: "#ffffff",
    lineHeight: "21px",
    letterSpacing: "0.03em",
    textAlign: "left" as const,
    margin: "0",
    // borderRadius: '0',
    border: "none",
    padding: "0px",
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "500",
  },
  selectStrategy: {
    display: "flex",
    width: "auto",
    alignItems: "center",
    justifyContent: "center",
    position: "relative" as const,
    cursor: "pointer",
  },
  span: {
    display: "flex",
    minWidth: "90%",
    textDecoration: "none",
    border: "solid 0.5px #B2B2B2B2",
    height: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    // gap: '9px',
    marginLeft: "15%",
  },

  tableContainer: {
    width: "100%",
    overflowX: "auto" as const,
  },
  table: {
    minWidth: "100%",
    borderRadius: "3px",
    borderSpacing: "0px",
    borderCollapse: "collapse" as const,
    padding: "0",
    marginTop: "0px",
    marginBottom: "94px",
    whiteSpace: "nowrap",
    backgroundColor: "#FFFFFF,",
  },
  tbody: {
    border: `solid ${Theme.colors.whitishGrey} `,
  },
  tableHeading: {
    fontFamily: "poppins",
    background: "#f3f6ff",
    height: "51px",
    borderRadius: "3px",
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  tableNav: {
    color: "#000",
    fontSize: "14px",
    letterSpacing: "0.84px",
    width: "100%",
  },
  tabelNavItems: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "21px",
    letterSpacing: "0.06em",
    textAlign: "center" as const,
    padding: "0 10px",
    gap: "10px",
  },
  topRow: {
    borderBottom: "solid",
    height: "45px",
    color: Theme.colors.whiteGrey,
    marginLeft: "30px",
  },
};

export default CustomerTabContent;
