import React, { useEffect, useRef, useState } from 'react';
import Footer from '../Footer';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Header, { CurrentPage } from '../Header/Header';
import AdminPageContent from './AdminPageContent';
import NavBar from '../NavBar';
import { Theme } from '../../Utils/Constants';
import AdminTableContent from './AdminTableData';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Dialogs/Loader';
import useOutSideClick from '../hooks/useOutSide';
import { checkIsAdminLoggedIn, getAboutMe } from '../../API/Auth';
import {
  deleteStrategyAPICall,
  fetchAdminTableData,
} from '../../API/AdminPageAPI';
import AddIcon from '@mui/icons-material/Add';
import { a } from '@react-spring/web';
interface AdminTableDataItem {
  id: string;
  is_live: boolean;
  strategy_name: string;
  minimum_investment_capital: number;
  capital_deployed: number;
  cagr_pct: number;
  risk: string;
  strategy_id: number;
  strategy_image: string;
}

const AdminPage = () => {
  const [adminTableData, setAdminTableData] = useState<AdminTableDataItem[]>(
    []
  );
  const [filteredAdminTableData, setFilteredAdminTableData] = useState<
    AdminTableDataItem[]
  >([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortingOption, setSortingOption] = useState('Popularity');
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleDeleteStrategy = async (strategyId: string) => {
    try {
      await deleteStrategyAPICall(strategyId);
      setAdminTableData((prevStrategies) =>
        prevStrategies.filter((strategy) => strategy.id !== strategyId)
      );

      setIsChanged(true);

      // console.log(adminTableData);
    } catch (error) {
      console.error('Error deleting strategy:', error);
    }
  };

  let sortedFilteredStrategies =
    adminTableData?.length > 0 ? [...adminTableData] : [...adminTableData];

  if (sortingOption === 'capital_deployed') {
    sortedFilteredStrategies.sort(
      (a, b) =>
        parseFloat(String(b.capital_deployed).replace(/,/g, '')) -
        parseFloat(String(a.capital_deployed).replace(/,/g, ''))
    );
    // Reverse the order for descending display

    // sortedFilteredStrategies.reverse();
  } else if (sortingOption === 'cagr_pct') {
    sortedFilteredStrategies.sort((a, b) => b.cagr_pct - a.cagr_pct);
    // Reverse the order for descending display
    // sortedFilteredStrategies.reverse();
  } else if (sortingOption === 'minimum_investment_capital') {
    sortedFilteredStrategies.sort(
      (a, b) =>
        parseFloat(String(b.minimum_investment_capital).replace(/,/g, '')) -
        parseFloat(String(a.minimum_investment_capital).replace(/,/g, ''))
    );
    // Reverse the order for descending display
    sortedFilteredStrategies.reverse();
  }

  const handleSearchChange = (e: string) => {
    setSearchQuery(e);
  };

  if (searchQuery.length >= 2) {
    sortedFilteredStrategies = sortedFilteredStrategies.filter(
      (strategy: any) => {
        // Concatenate all relevant fields into a single string
        const strategyContent = Object.values(strategy).join(' ').toLowerCase();
        // Check if the concatenated string contains the search query
        return strategyContent.includes(searchQuery.toLowerCase());
      }
    );
  }

  useEffect(() => {
    const checkAdminStatus = async () => {
      const isLoggedIn = await checkIsAdminLoggedIn();
      if (isLoggedIn.loggedIn) {
      } else {
        navigate('/');
      }
    };

    checkAdminStatus();
  }, []);

  const apiUrl =  process.env.REACT_APP_BASE_URL as string + 'strategy/strategy-list-v2';

  const handleUpdateIsLive = async (index: number, newValue: boolean) => {
    const apiUrl = process.env.REACT_APP_BASE_URL as string + 'strategy/strategy-toggle';
    try {
      const apiUrl =
        (process.env.REACT_APP_BASE_URL as string) +
        'strategy/strategy-live-toggle';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          strategyId: adminTableData[index].strategy_id,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to toggle strategy');
      }

      setAdminTableData((prevData) => {
        const newData = [...prevData];
        newData[index].is_live = newValue;
        return newData;
      });
    } catch (error) {
      console.error('Error toggling strategy:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        fetchAdminTableData(apiUrl).then((data) => {
          if((Array.isArray(data))) {
            setAdminTableData(data);
          }
        }).catch((error) => {
          console.error('Error fetching strategy list:', error);
        });
      } catch (error) {
        console.error('Error fetching strategy list:', error);
        console.log('Error fetching strategy list:', error);
        
      }
    };

    fetchData();
    setIsLoading(false);
  }, [isChanged]);

  const handleDropDown = () => {
    // if (isDropDownOpen) {
    //   setIsDropDownOpen(false);
    // } else {
    //   setIsDropDownOpen(true);
    // }
    setIsDropDownOpen(!isDropDownOpen);
  };

  useOutSideClick(containerRef, () => {
    if (isDropDownOpen) {
      setIsDropDownOpen(false);
    }
  });

  // Function to handle sorting option change
  const handleSortingChange = (option: string) => {
    setSortingOption(option);
    setIsDropDownOpen(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header currentPage={CurrentPage.AI_Strategies} />
      <div style={{ }} className='flex admin-container'>
        <div className='admin-navbar'>
          <NavBar />
        </div>
        {/* <AIStrategiesFilter /> */}
        <div className='admin-landing-page'>
          <div className='admin-header'>
            <div className='admin-tabs'>
              <div className=' tab-option'>
                <span
                  style={{
                    fontWeight: Theme.fontWeight.medium,
                    fontSize: Theme.fontSizes.h3,
                  }}
                >
                  All Strategies
                </span>
              </div>

              {/* <div className='Popularity tab-option'>
                <span style={{ fontWeight: '300' }}>Sort by &nbsp;</span>{' '}
                Popularity &nbsp;
                <span className='dropdown-icon'>
                  <KeyboardArrowDownIcon />
                </span>
              </div> */}
              {/* <div className='tab-option'>
                <span style={{ fontWeight: '300' }}>Sort by : &nbsp;</span>
                <select
                  style={{
                    border: 'none',
                    padding: '5px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '5px',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    fontWeight: '500',
                    fontSize: '14px',
                  }}
                >
                  <option value='popularity'>Popularity</option>
                  <option value='option2'>Option 2</option>
                  <option value='option3'>Option 3</option>
                </select>
              </div> */}
              <div className='Popularity tab-option flex-center'>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: '300',
                    fontSize: '16px',
                    position: 'relative',
                  }}
                  ref={containerRef}
                >
                  Sort by : &nbsp;{' '}
                  <div onClick={handleDropDown}>
                    <button
                      className='sortby-top-option'
                      style={{
                        display: 'flex',
                        border: 'none',
                        alignItems: 'center',
                        fontSize: '16px',
                        padding: '0',
                        backgroundColor: 'transparent',
                        color: 'black',
                        cursor: 'pointer',
                      }}
                    >
                      {isDropDownOpen ? (
                        <>
                          Select
                          <KeyboardArrowUpIcon />
                        </>
                      ) : (
                        <>
                          {sortingOption === 'capital_deployed'
                            ? 'Capital Deployed'
                            : sortingOption === 'cagr_pct'
                            ? 'Earning'
                            : sortingOption === 'Popularity'
                            ? 'Popularity'
                            : sortingOption}
                          <KeyboardArrowDownIcon />
                        </>
                      )}
                    </button>
                  </div>
                  {isDropDownOpen && (
                    <div className='sortby-options'>
                      <button
                        className='sort-option'
                        style={{
                          color: 'rgba(0, 0, 0, 0.70)',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSortingChange('Popularity')}
                      >
                        Popularity
                      </button>
                      {/* <button
                        className='sort-option'
                        style={{
                          color: 'rgba(0, 0, 0, 0.70)',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSortingChange('capital_deployed')}
                      >
                        Capital Deployed
                      </button> */}
                      <button
                        className='sort-option'
                        style={{
                          color: 'rgba(0, 0, 0, 0.70)',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSortingChange('cagr_pct')}
                      >
                        Earnings
                      </button>
                    </div>
                  )}
                </span>
              </div>
              <div className='search-bar tab-option'>
                <SearchIcon /> &nbsp;
                <input
                  className='search-input'
                  type='text'
                  placeholder='Search any strategies'
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </div>
            </div>

            <Link
              className='create-strategy-button flex flex-center'
              to='/admin-create-strategy'
            >
              <AddIcon /> Create Strategy
            </Link>
          </div>

          <div className='admin-tab-content flex flex-center'>
            <AdminPageContent
              // AdminTableData={adminTableData}
              AdminTableData={sortedFilteredStrategies}
              onUpdateIsLive={handleUpdateIsLive}
              onDeleteStrategy={handleDeleteStrategy}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: Theme.colors.backgroundF3,
          paddingTop: Theme.gapLarge,
        }}
      >
        <Footer />
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex' as const,
  },
  navBar: {
    // minWidth: Theme.navBarWidth,
    width: '20%',
  },
  fullPageContainer: {
    padding: Theme.gapSmall,
  },
};

export default AdminPage;
