import { create } from 'zustand';
import { AdminTableDataItem } from '../API/AdminPageAPI';

type AppManagementStore = {
  isAuthenticated: boolean;
  isLoading: boolean;
  profileName: string | null;
  adminTableData: AdminTableDataItem[];
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setProfileName: (profileName: string) => void;
  // setAdminTableData: (adminTableData: AdminTableDataItem) => void;
  setAdminTableData: (
    adminTableData: AdminTableDataItem[] | AdminTableDataItem
  ) => void; // Updated type
};

const useAppStore = create<AppManagementStore>((set) => ({
  isAuthenticated: false,
  isLoading: true,
  profileName: null,
  adminTableData: [],
  setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setProfileName: (profileName) => set({ profileName }),
  // setAdminTableData: (adminTableData) => set({ adminTableData }),
  setAdminTableData: (adminTableData) => {
    const data = Array.isArray(adminTableData)
      ? adminTableData
      : [adminTableData];
    set({ adminTableData: data });
  },
}));

export default useAppStore;
