import React, { useEffect, useState } from "react";
// import AuthSetupPage from "./AuthSetupPage";
import { Styles, Theme, ThemeTypes } from "../../Utils/Constants";
import { checkIsAdminLoggedIn, loginAdmin } from "../../API/Auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();

    const loginSuccess = await loginAdmin(username, password);
   // console.log(loginSuccess);

    if (loginSuccess) {
      navigate("/admin");
    } else {
      console.log("error");
    }
  };

  const isAdminLoggedIn = async () => {
    const result = await checkIsAdminLoggedIn();
    return result.loggedIn;
  };

  useEffect(() => {
    const checkAdminStatus = async () => {
      const isAdmin = await isAdminLoggedIn();
      if (!isAdmin) {
        navigate("/", { replace: true });
      }
    };

    checkAdminStatus();
  }, []);

  return (
    <>
      <div style={styles.flexContainer}>
        <div style={styles.centeredContainer}>
          <p style={{ ...Styles.h1Text, textTransform: "none" as const }}>
            Admin <span style={styles.titleColorText}>Login</span>
            {/* <span style={styles.titleColorText}>Moneyy.ai</span> */}
          </p>
          <div style={styles.loginBox}>
            <form onSubmit={handleLogin}>
              <div style={styles.inputCont}>
                <input
                  type="text"
                  style={styles.inputElement}
                  placeholder="username"
                  autoComplete="username"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                  required
                />
              </div>
              <div style={styles.inputCont}>
                <input
                  type="password"
                  style={styles.inputElement}
                  placeholder="Password"
                  autoComplete="current-password"
                  value={password}
                  required
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              <div style={styles.loginButtonBox}>
                <button
                  type="submit"
                  style={{
                    ...ThemeTypes.yellowButton,
                    margin: "0 auto",
                    height: "40px",
                    alignItems: "center",
                  }}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  centeredContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  titleColorText: {
    color: Theme.colors.blueSolid,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "32px 0 48px 0",
  },
  loginBox: {
    backgroundImage: Theme.colors.blueWhiteGradient,
    borderRadius: Theme.borderRadiusLarge,
    padding: Theme.gapLarge,
    margin: Theme.gapLarge,
    boxShadow: "0 0 16px 0 " + Theme.colors.yellow,
    justifyContent: "center",
    width: "80%",
    maxWidth: "600px",
    maxHeight: "400px",
  },
  inputCont: {
    display: "flex",
    justifyContent: "center" as const,
    marginBottom: "30px",
    height: "50px",
    padding: "5px",
  },
  inputElement: {
    minWidth: "280px",
    minHeight: "28px",
  },
  loginButtonBox: {
    marginTop: Theme.gapLarge,
    height: "40px",
  },
};

export default Login;
