import React, { useEffect, useState } from 'react';
import './killswitch.css';
import Header, { CurrentPage } from '../../Header/Header';
import NavBar from '../../NavBar';
import DoneIcon from '@mui/icons-material/Done';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Dialogs/Loader';
import {
  fetchStrategyDetails,
  StrategyDetails,
} from '../../../Utils/FetchStrategyDetails';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { checkIsAdminLoggedIn } from '../../../API/Auth';
import CustomerTabContent from './CustomerTabContent';
import CustomerHistoricalTab from './CustomerHostoricalTab';
import { AdminTableDataItem, fetchAdminTableData } from '../../../API/AdminPageAPI';

interface KillSwitchParams {
  strategyId?: number;
}

// Define an interface representing the structure of each item in the results array
interface StrategyStatus {
  userId: string;
  strategyId: string;
  triggerTime: string;
  errorMessage: string | null;
  signalStatus: string | null;
  isSuccess: boolean | null;
  signalId: string | null;
}

const KillSwitchPage: React.FC = () => {
  const { strategyId } = useParams() as KillSwitchParams;
  const [isToggled, setToggled] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [runToday, setRunToday] = useState(false);
  const [strategyDetails, setStrategyDetails] =
    useState<StrategyDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  // Define a state variable to hold the table data
  const [tableData, setTableData] = useState<StrategyStatus[]>([]);
  const [isFetchingData, setIsFetchingData] = useState(false); // Track if data is being fetched
  const [currentTab, setCurrentTab] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      const isLoggedIn = await checkIsAdminLoggedIn();
      if (isLoggedIn.loggedIn) {
      } else {
        navigate('/');
      }
    };

    checkAdminStatus();
  }, []);

  const handleToggle = () => {
    setToggled(!isToggled);
  };

  const handleRunToday = () => {
    setRunToday(!runToday);
  };

  const [tab, setTab] = useState(1);

  const fetchToggleState = async () => {
    const apiUrl = process.env.REACT_APP_BASE_URL as string + `readKillSwitch/${strategyId}/`;
    try {
      const response = await fetch(
        apiUrl,
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      // console.log(data);

      setIsOn(data.KillSwitch);
    } catch (error) {
      console.error('Error fetching toggle state:', error);
    }
  };

  const handleOnOff = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MASTER_KILL_SWITCH}/${strategyId}/${
          isOn ? "disableKillSwitch/" : "enableKillSwitch/"
        }`,
        {
          method: 'GET',
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setIsOn(!isOn);
    } catch (error) {
      console.error('Error toggling state:', error);
    }
  };

  const apiUrl =  process.env.REACT_APP_BASE_URL as string + 'strategy/strategy-list-v2';
  const [adminTableData, setAdminTableData] = useState<AdminTableDataItem[]>(
    []
  );


  const fetchData = async () => {
    try {
     
      fetchAdminTableData(apiUrl).then((data) => {
        if((Array.isArray(data))) {

         const newData:any = data.filter((item) => item.strategy_id === strategyId);
         
         setStrategyDetails(newData[0]);
          setAdminTableData(newData);
          setIsLoading(false);
        }
      }).catch((error) => {
        console.error('Error fetching strategy list:', error);
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching strategy list:', error);
      console.log('Error fetching strategy list:', error);
      setIsLoading(false);
      
    }
  };

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
        
    //     const details = await fetchStrategyDetails(String(strategyId));

    //     if (details) {
    //       setStrategyDetails(details);
    //     } else {
    //       console.error('Failed to fetch strategy details.');
    //     }
    //   } catch (error) {
    //     console.error('Error in fetchData:', error);
    //   }
    //   setIsLoading(false);
    // };
   
    fetchData();
    fetchToggleState();
  }, [strategyId]);

  useEffect(() => {
    const fetchTableData = async () => {
      const apiUrl = process.env.REACT_APP_BASE_URL as string + `strategy/status/user/?strategyID=${strategyId}`
      try {
        setIsFetchingData(true); // Set fetching flag to true
        const response = await fetch(
          apiUrl,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log('table data', data);

        // Update the state variable with the fetched data
        setTableData(data.results);
      } catch (error) {
        console.error('Error fetching table data:', error);
      } finally {
        setIsFetchingData(false); // Set fetching flag back to false after fetching
      }
    };
    fetchTableData();
  }, [strategyId]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header currentPage={CurrentPage.AI_Strategies} />
      <div className='admin-create-strategy-container'>
        <div style={{ minWidth: '20%' }} className='admin-navbar'>
          <NavBar />
        </div>

        <div
          style={{ minWidth: '75%' }}
          className='admin-create-startegy-landing-page admin-kill-switch-landing-page'
        >
          <div className='admin-create-strategy-tab-container'>
            <div className='admin-create-strategy-tab-container'>
              <div style={{}} className='admin-create-strategy-tabs'>
                {/* <h5 className={'active'}>S</h5> */}
                <h5
                  className={currentTab === 0 ? 'active' : ''}
                  onClick={() => {
                    setCurrentTab(0);
                  }}
                >
                  Strategy Monitoring
                </h5>
                <h5
                  className={currentTab === 1 ? 'active' : ''}
                  onClick={() => {
                    setCurrentTab(1);
                  }}
                >
                  Customer
                </h5>
                <h5
                  className={currentTab === 2 ? 'active' : ''}
                  onClick={() => {
                    setCurrentTab(2);
                  }}
                >
                  Customer(Historical)
                </h5>
              </div>
              <div className='admin-header-buttons'></div>
            </div>
          </div>

          {currentTab === 0 && (
            <div className='kill-switch-content'>
              <div className='kill-id'>
                <span>Id: {strategyDetails?.strategy_id}</span>
              </div>
              <div className='strategy-heading'>
                {strategyDetails?.strategy_name}
              </div>
              <div className='strategy-kill-switch-card'>
                <div className='strategy-kill-switch-card-first-half'>
                  <DoDisturbIcon /> &nbsp;
                  <div className='strategy-kill-switch-card-text'>
                    Kill Switch
                    <p>Manage Your Kill Switch</p>
                  </div>
                </div>
                <div className='strategy-kill-switch-card-second-half'>
                  {/* <div className='kill-switch-toggle-btn'>
                  <div
                    className={`toggle-container ${isToggled ? "active" : ""}`}
                    onClick={handleToggle}
                  >
                    <div className={`slider ${isToggled ? "active" : ""}`} />
                  </div>
                  Restart
                </div> */}
                  {/* <div className='kill-switch-toggle-btn'>
                  <div
                    className={`toggle-container ${runToday ? "active" : ""}`}
                    onClick={handleRunToday}
                  >
                    <div className={`slider ${runToday ? "active" : ""}`} />
                  </div>
                  Run Today
                </div> */}
                  <div className='kill-switch-toggle-btn'>
                    <div
                      className={`toggle-container ${isOn ? 'active' : ''}`}
                      onClick={handleOnOff}
                    >
                      <div className={`slider ${isOn ? 'active' : ''}`} />
                    </div>
                    {isOn ? 'On' : 'Off'}
                  </div>
                </div>
              </div>

              <div className='strategy-kill-switch-details'>
                <div className='strategy-kill-switch-details-tabs'>
                  {/* <div
                  className={`strategy-kill-switch-details-tab ${tab === 0 ? "strategy-kill-switch-details-tab-active" : ""
                    }`}
                  onClick={() => {
                    setTab(0);
                  }}
                >
                  Matrices
                </div> */}

                  {/* <div
                    className={`strategy-kill-switch-details-tab ${
                      tab === 1 ? 'strategy-kill-switch-details-tab-active' : ''
                    }`}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Algo Details
                  </div> */}
                </div>

                {/* <div className='strategy-kill-switch-details-content'>
                  {tab === 0 ? (
                    <div className='strategy-kill-switch-details-content-matrices'>
                      
                    </div>
                  ) : (
                    <div className='strategy-kill-switch-details-content-algo'>
                      <div className='strategy-kill-switch-details-content-algo-top'>
                    

                        <div className='strategy-kill-switch-details-content-algo-top-prev-next'>
                          <div className='center'>{`< Previous `}</div>
                          <div className='center'>{`Next >`}</div>
                        </div>
                      </div>
                      <div className='strategy-kill-switch-details-content-algo-table-container'>
                        <table className='strategy-kill-switch-details-content-algo-table'>
                          <thead>
                            <th>User ID</th>
                            <th>Strategy ID</th>
                            <th>Trigger Time</th>
                            <th>Error Message</th>
                            <th>Signal ID</th>
                            <th>Signal Status</th>
                            <th>IsSuccess</th>
                          </thead>
                          <tbody>
                            {tableData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.userId}</td>
                                <td>{row.strategyId}</td>
                                <td>{row.triggerTime}</td>
                                <td>{row.errorMessage}</td>
                                <td>{row.signalId}</td>
                                <td>{row.signalStatus}</td>
                                <td>
                                  {row.isSuccess !== null
                                    ? row.isSuccess.toString()
                                    : 'N/A'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          )}

          {currentTab === 1 && <CustomerTabContent strategyid={strategyDetails?.strategy_id} />}
          {currentTab === 2 && <CustomerHistoricalTab strategyid={strategyDetails?.strategy_id} />}
        </div>
      </div>
    </>
  );
};

export default KillSwitchPage;

