import { ApolloClient, InMemoryCache, createHttpLink, gql } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';

export interface PositionDetails {
    PositionID: string;
    Position: string;
    Quantity: string;
    EntryAveragePrice: string | number;
    Entry: boolean;
    Direction: string;
    ExitAveragePrice: string | null;
    PnL: string | null;
    ExitOrderStatus: string | null;
    strategy_id?: string;
    LastUpdated: string;
    OrderStatus?: string;
  }

  export interface UserLiveTradeAPIInterface {
    response_code: number;
    error_code: string;
    error_message: string;
    request_id: string;
    time_taken: number;
    request_timestamp: string;
    response_message: string;
    response_data: {
      PositionDetails: PositionDetails[];
    };
  }
  export const fetchAllTradingPositionByStrategyAndUserId = async (
    userId: string,
    strategy_id: string
  ) => {
    // Define the GraphQL query
    const GET_POSITION_IDS = gql`
      query MyQuery($strategyId: String!, $userId: String!) {
        getPositionIdsByStrategyId(StrategyID: $strategyId, UserId: $userId) {
          MoneyUserOrderDetails(userId: $userId) {
            PositionID
            Position
            TradingDate
            Entry
            Quantity
            ExitAveragePrice
            AveragePrice
            Lots
            ExitOrderStatus
            Direction
            LastUpdated
            OrderStatus
            MoneyDevPositionLTP {
              LTP
            }
          }
        }
      }
    `;
  
    // Create an Apollo client
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_APOLLO_CLIENT as string,
    });
  
    // Set the API key
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          'x-api-key': process.env.REACT_APP_APPSYNC_API_KEY as string,
        },
      };
    });
  
    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  
    let userLiveTradeDetails: UserLiveTradeAPIInterface = {
      response_data: {
        PositionDetails: [],
      },
      response_code: 0,
      error_code: '',
      error_message: '',
      request_id: '',
      time_taken: 0,
      request_timestamp: '',
      response_message: '',
    };
  
    try {
      // Execute the GraphQL query
      const { data } = await client.query({
        query: GET_POSITION_IDS,
        variables: {
          strategyId: strategy_id,
          userId: userId,
        },
      });
  
      let positionDetails: any[] = data.getPositionIdsByStrategyId;
      positionDetails.forEach((pos) => {
        if (pos.MoneyUserOrderDetails && pos.MoneyUserOrderDetails.Position) {
          let livePositionDetails: PositionDetails = {
            Position: pos.MoneyUserOrderDetails.Position,
            EntryAveragePrice: pos.MoneyUserOrderDetails.AveragePrice,
            Quantity: pos.MoneyUserOrderDetails.Quantity,
            ExitAveragePrice: pos.MoneyUserOrderDetails.ExitAveragePrice,
            Entry: pos.MoneyUserOrderDetails.Entry,
            PositionID: pos.MoneyUserOrderDetails.PositionID,
            Direction: pos.MoneyUserOrderDetails.Direction,
            ExitOrderStatus: pos.MoneyUserOrderDetails.ExitOrderStatus,
            PnL: null,
            LastUpdated: pos.MoneyUserOrderDetails.LastUpdated,
            OrderStatus: pos.MoneyUserOrderDetails.OrderStatus,
          };
          userLiveTradeDetails.response_data.PositionDetails.push(
            livePositionDetails
          );
        } else {
          console.error('Position property is null or undefined');
        }
      });
      return userLiveTradeDetails;
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
      return userLiveTradeDetails;
    }
  };



