import React, { useEffect, useState } from 'react';
import { ThemeTypes, Theme } from '../../../../Utils/Constants';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinkIcon from '@mui/icons-material/Link';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AddIcon from '@mui/icons-material/Add';
import { uploadFileAPI, uploadImageAPI } from '../../../../API/FileUploadAPI';
import { a } from '@react-spring/web';
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

interface QnA {
  question: string;
  answer: string;
  isActive: any;
}
const AdminCreateContent = () => {
  const [formData, setFormData] = useState({
    strategyAuthor: '',
    strategyName: '',
    strategyShortDescription: '',
    strategyImage: '',
    trending: '',
    strategyTag: '',
    faqs: [{ question: '', answer: '', isActive: true }] as Array<QnA>,
    overview: '',
    CapitalDeployed: '',
    minimum_investment_capital: '',
    cagr_pct: '',
    insights_information: {
      blog_post: {
        object_url: '',
        text: '',
      },
      methodology: {
        object_url: '',
        text: '',
      },
      factsheets: {
        object_url: '',
        text: '',
      },
    },
    risk: '',
  });
  let initialId = '#10987';
  const [isEditing, setIsEditing] = useState(false);
  const [editedId, setEditedId] = useState(initialId);
  const [strategyName, setStrategyName] = useState('');
  const [selectedTag, setSelectedTag] = useState<string>('New');

  const [strategyImgpreview, setStrategyImgpreview] = useState<string | null>();
  const [selectedStrategyImage, setSelectedStrategyImage] =
    useState<File | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>('');

  const [blogPdfpreview, setblogPdfpreview] = useState<any>(null);
  const [blogSectionText, setBlogSectionText] = useState('');
  const [selectedBlogPdf, setselectedBlogPdf] = useState<File | null>(null);
  const [uploadedBlogPdfUrl, setuploadedBlogPdfUrl] = useState<string>('');

  const [methodologyPdfPreview, setmethodologyPdfPreview] = useState<
    string | null
  >();
  const [methodologySectionText, setMethodologySectionText] = useState('');
  const [SelectedMethodologyPdf, setSelectedMethodologyPdf] =
    useState<File | null>(null);
  const [uploadedMethodologyPdfUrl, setuploadedMethodologyPdfUrl] =
    useState<string>('');

  const [factSheetPdfPreview, setfactSheetPdfPreview] = useState<
    string | null
  >();
  const [factSheetSectionText, setFactSheetSectionText] = useState('');
  const [selectedFactSheetPdf, setselectedFactSheetPdf] = useState<File | null>(
    null
  );
  const [uploadedFactSheetPdfUrl, setuploadedFactSheetPdfUrl] =
    useState<string>('');

  const [selectedRisk, setSelectedRisk] = useState('');

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  // const newPlugin = defaultLayoutPlugin();

  // const handleQnAInputChange = (
  //   index: number,
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const { name, value } = event.target;
  //   const newFaqs = [...formData.faqs];
  //   newFaqs[index][name as keyof QnA] = value as string;
  //   setFormData({
  //     ...formData,
  //     faqs: newFaqs,
  //   });
  // };
  const handleQnAInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const newFaqs = [...formData.faqs];

    // Type assertion
    if (name === 'isActive') {
      newFaqs[index][name as keyof QnA] = value === ('true' as any);
    } else {
      newFaqs[index][name as keyof QnA] = value as any;
    }

    setFormData({
      ...formData,
      faqs: newFaqs,
    });
  };

  const addQna = () => {
    setFormData({
      ...formData,
      faqs: [...formData.faqs, { question: '', answer: '', isActive: true }],
    });
  };

  const removeQna = (index: number) => {
    const newFaqs = [...formData.faqs];
    newFaqs.splice(index, 1);
    setFormData({
      ...formData,
      faqs: newFaqs,
    });
  };

  const handleEditorStateChange = (newEditorState: EditorState): void => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const overview = JSON.stringify(convertToRaw(contentState));
    setFormData({
      ...formData,
      overview,
    });
  };

  const [selectedIcon, setSelectedIcon] = useState<File | null>(null);
  const [description, setDescription] = useState('');

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const clearEditorContent = () => {
    const emptyEditorState = EditorState.createEmpty();
    setEditorState(emptyEditorState);
    setFormData({
      ...formData,
      overview: '',
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStrategyName(event.target.value);
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTag(e.target.value);
  };

  const handleRiskSelection = (value: string) => {
    setFormData({
      ...formData,
      risk: value,
    });
    // setSelectedRisk(value);
  };

  const handleUploadClick = () => {
    console.log('Selected Icon:', selectedIcon);
  };

  const handleAddLinkClick = () => {
    console.log('Description:', description);
  };

  const handleBlogTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBlogSectionText(event.target.value);
  };
  const handleMethodologyTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMethodologySectionText(event.target.value);
  };
  const handleFactSheetTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFactSheetSectionText(event.target.value);
  };

  const handleStrategyImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] as File | undefined;

    if (file) {
      const reader = new FileReader();
      setSelectedStrategyImage(file);
      reader.onload = () => {
        setStrategyImgpreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedStrategyImage(null);
      setStrategyImgpreview(null);
    }
  };

  const handleBlogPdfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] as File | undefined;
    console.log(file);
    if (file) {
      const reader = new FileReader();
      setselectedBlogPdf(file);
      reader.onload = () => {
        setblogPdfpreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setselectedBlogPdf(null);
      setblogPdfpreview(null);
    }

    // if (file) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   // setselectedBlogPdf(file);
    //   reader.onload = (e: any) => {
    //     // setblogPdfpreview(e.target.result);
    //     setblogPdfpreview(file);
    //   };
    // } else {
    //   setselectedBlogPdf(null);
    //   setblogPdfpreview(null);
    // }
  };
  const handleMethodologyPdfChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] as File | undefined;

    if (file) {
      const reader = new FileReader();
      setSelectedMethodologyPdf(file);
      reader.onload = () => {
        setmethodologyPdfPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedMethodologyPdf(null);
      setmethodologyPdfPreview(null);
    }
  };
  const handleFactsheetPdfChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0] as File | undefined;

    if (file) {
      const reader = new FileReader();
      setselectedFactSheetPdf(file);
      reader.onload = () => {
        setfactSheetPdfPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setselectedFactSheetPdf(null);
      setfactSheetPdfPreview(null);
    }
  };

  function base64ToBinary(base64: string): ArrayBuffer {
    const binaryString = atob(base64.split(',')[1]);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  }

  function getBase64(file: any, callback: any) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const base64Data = reader.result?.toString() || '';
      const binaryData = base64ToBinary(base64Data);
      callback(binaryData);
    });
    reader.readAsDataURL(file);
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleStrategyTag = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormData({ ...formData, strategyTag: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
    const apiUrl = process.env.REACT_APP_BASE_URL as string + 'strategy/create-new-strategy';
    try {
      const response = await fetch(apiUrl,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          body: JSON.stringify({
            AdminID: 'admin123',
            ...formData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(formData);
      console.log(response);

      alert(
        responseData.response_message
          ? responseData.response_message
          : responseData.error_message
      );
    } catch (error) {
      console.error('Error creating new strategy:', error);
    }
  };

  useEffect(() => {
    if (selectedStrategyImage != null) {
      getBase64(selectedStrategyImage, async function (binaryData: any) {
        const response = await uploadImageAPI(
          'strategy',
          selectedStrategyImage,
          binaryData
        );
        const imageData = await response;
        console.log(imageData);

        setUploadedImageUrl(imageData.object_url);
        console.log(imageData.object_url);
        if (imageData.object_url !== '') {
          console.log(imageData.object_url, 'undertae');

          setFormData({
            ...formData,
            strategyImage: imageData.object_url,
          });
        }
      });
    }
    console.log(formData);

    if (uploadedImageUrl !== '') {
      setFormData({
        ...formData,
        strategyImage: uploadedImageUrl,
      });
    }

    console.log(formData);
  }, [selectedStrategyImage]);

  useEffect(() => {
    if (selectedBlogPdf != null) {
      getBase64(selectedBlogPdf, async function (binaryData: any) {
        const response = await uploadFileAPI(
          'strategy',
          selectedBlogPdf,
          selectedBlogPdf
        );
        const imageData = await response;
        setuploadedBlogPdfUrl(imageData.object_url);
        console.log(imageData.object_url);
      });
    }
  }, [selectedBlogPdf]);

  useEffect(() => {
    if (SelectedMethodologyPdf != null) {
      getBase64(SelectedMethodologyPdf, async function (binaryData: any) {
        const response = await uploadFileAPI(
          'strategy',
          SelectedMethodologyPdf,
          binaryData
        );
        const imageData = await response;
        setuploadedMethodologyPdfUrl(imageData.object_url);
        console.log(imageData.object_url);
      });
    }
  }, [SelectedMethodologyPdf]);
  useEffect(() => {
    if (selectedFactSheetPdf != null) {
      getBase64(selectedFactSheetPdf, async function (binaryData: any) {
        const response = await uploadFileAPI(
          'strategy',
          selectedFactSheetPdf,
          selectedFactSheetPdf
        );
        const imageData = await response;
        setuploadedFactSheetPdfUrl(imageData.object_url);
        console.log(imageData.object_url);
      });
    }
  }, [selectedFactSheetPdf]);

  useEffect(() => {
    if (uploadedBlogPdfUrl !== '') {
      setFormData((prevState) => ({
        ...prevState,
        insights_information: {
          ...prevState.insights_information,
          blog_post: {
            ...prevState.insights_information.blog_post,
            object_url: uploadedBlogPdfUrl,
            text: blogSectionText,
          },
        },
      }));
    }

    if (uploadedMethodologyPdfUrl !== '') {
      setFormData((prevState) => ({
        ...prevState,
        insights_information: {
          ...prevState.insights_information,
          methodology: {
            ...prevState.insights_information.methodology,
            object_url: uploadedMethodologyPdfUrl,
            text: methodologySectionText,
          },
        },
      }));
    }

    if (uploadedFactSheetPdfUrl !== '') {
      setFormData((prevState) => ({
        ...prevState,
        insights_information: {
          ...prevState.insights_information,
          factsheets: {
            ...prevState.insights_information.factsheets,
            object_url: uploadedFactSheetPdfUrl,
            text: factSheetSectionText,
          },
        },
      }));
    }
    console.log(uploadedBlogPdfUrl);
  }, [
    blogSectionText,
    methodologySectionText,
    factSheetSectionText,
    uploadedMethodologyPdfUrl,
    uploadedBlogPdfUrl,
    uploadedFactSheetPdfUrl,
  ]);

  return (
    <div className='admin-content-editing-container'>
      <div className='admin-content-editing-heading'>
        <span className='admin-strategy-heading'>General Informations</span>

        <div className='id-editor'>
          {/* {isEditing ? (
            <>
              <input
                type='text'
                value={editedId}
                onChange={handleInputChange}
                className='id-editor'
              />
              <button onClick={handleSaveClick}>Save</button>
            </>
          ) : (
            <>
              <span>ID: #{editedId}</span>
              <section onClick={handleEditClick}>
                <EditIcon className='edit-icon' />
              </section>
            </>
          )} */}
        </div>
      </div>
      <form onSubmit={handleSubmit} className='admin-content-editing-section'>
        <div className='horizontal-line'></div>
        <div className='import-section'>
          <div className='import-button-section'>
            {!strategyImgpreview && (
              <label htmlFor='imageInput' className='import-button'>
                <FileUploadIcon />
                Import
              </label>
            )}

            {strategyImgpreview && (
              <div className='edit-image-section'>
                <img
                  className='selected-image'
                  src={strategyImgpreview}
                  alt='Selected Imagez'
                />
                <label htmlFor='imageInput' className='edit-image-icon'>
                  <EditIcon />
                </label>
              </div>
            )}

            <input
              type='file'
              id='imageInput'
              style={{ display: 'none' }}
              onChange={handleStrategyImageChange}
              // value={formData.strategyImage}
              // onChange={handleChange}
              accept='image/*'
              // required
            />

            {/* <input
              type='text'
              name='strategyImage'
              value={formData.strategyImage}
              onChange={handleChange}
            /> */}

            <span>
              {strategyImgpreview ? '(Change Image)' : '(Select Image)'}
            </span>
          </div>

          <div className='strategy-name-info'>
            <label className='admin-strategy-heading'>Strategy Name:</label>
            <input
              type='text'
              id='strategyName'
              name='strategyName'
              value={formData.strategyName}
              onChange={handleChange}
              required
            />
          </div>

          <div className='strategy-tag'>
            <label className='admin-strategy-heading'>Strategy Tag:</label>
            <div>
              <input
                type='radio'
                id='new'
                value='New'
                checked={formData.strategyTag === 'New'}
                onChange={handleStrategyTag}
              />
              <label htmlFor='new'>New</label>
              <input
                type='radio'
                id='popular'
                value='Popular'
                checked={formData.strategyTag === 'Popular'}
                onChange={handleStrategyTag}
              />
              <label htmlFor='popular'>Popular</label>
              <input
                type='radio'
                id='highReturn'
                value='High Return'
                checked={formData.strategyTag === 'High Return'}
                onChange={handleStrategyTag}
              />
              <label htmlFor='highReturn'>High Return</label>
            </div>
          </div>

          {/* <button onClick={handleImportClick}>Submit</button> */}
        </div>

        {/*  */}
        <div className='admin-content-middle-section'>
          <div className='admin-content-middle-individual-section'>
            <label className='admin-strategy-heading' htmlFor='strategyAuthor'>
              Author Name:
            </label>
            <textarea
              value={formData.strategyAuthor}
              id='strategyAuthor'
              name='strategyAuthor'
              rows={4}
              cols={50}
              onChange={handleChange}
              required
              placeholder='Type Something...'
            />
          </div>

          {/*  */}
          <div className='admin-content-middle-individual-section'>
            <label
              className='admin-strategy-heading'
              htmlFor='strategyShortDescription'
            >
              Strategy Description:
            </label>
            <textarea
              id='strategyShortDescription'
              rows={4}
              cols={50}
              name='strategyShortDescription'
              value={formData.strategyShortDescription}
              onChange={handleChange}
              required
              placeholder='Type Something...'
            />
          </div>
          {/*  */}

          <div className='admin-content-middle-individual-section'>
            <label className='trending-header' htmlFor='trending'>
              <article>
                Trending &nbsp; <EditIcon className='edit-icon' />
              </article>{' '}
              <span>
                (Note:Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt...)
              </span>
            </label>
            <textarea
              id='trending'
              rows={4}
              cols={50}
              placeholder='Type Something...'
              name='trending'
              value={formData.trending}
              onChange={handleChange}
            />
          </div>

          {/* editor section */}

          <div
            className='admin-content-middle-individual-section'
            style={{ marginBottom: '12px' }}
          >
            <label
              className='admin-strategy-heading'
              htmlFor='strategyDescription'
            >
              <article>
                Strategy Overview: &nbsp;
                {/* <EditIcon className='edit-icon' /> */}
              </article>{' '}
            </label>
            <textarea
              id='overview'
              rows={4}
              cols={50}
              name='overview'
              value={formData.overview}
              onChange={handleChange}
              placeholder='Description...'
            />
            {/* <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorStateChange}
                  placeholder='Your Description...'
                  editorClassName='custom-editor-class'
                  wrapperClassName='custom-wrapper-class'
                  toolbar={{
                    options: ['blockType', 'inline', 'link', 'list', 'image'],
                    blockType: {
                      inDropdown: true,
                      options: [
                        'Normal',
                        'H1',
                        'H2',
                        'H3',
                        'H4',
                        'H5',
                        'H6',
                        'Blockquote',
                        'Code',
                      ],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                    inline: {
                      options: ['bold', 'italic'],
                      bold: { className: 'custom-bold-class' },
                      italic: { className: 'custom-italic-class' },
                    },

                    list: {
                      options: ['unordered', 'ordered'],
                    },
                    link: {
                      options: ['link', 'unlink'],
                    },
                    textAlign: {
                      inDropdown: false,
                      className: 'custom-text-align-class',
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ['left', 'center', 'right'],
                    },
                    image: {
                      // icon: FileUploadIcon,
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      uploadCallback: undefined,
                      previewImage: false,
                      inputAccept:
                        'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: 'auto',
                        width: 'auto',
                      },
                    },
                  }}
                /> */}
          </div>
          {/*  */}

          {/*  qna section */}
          <div className='ques-ans-section'>
            <span className='admin-strategy-heading'>Q&As (in Dropdwon)</span>
            <div className='qna-ans'>
              {formData.faqs.map((qna, index) => {
                return (
                  <div className='qna mb-10' key={index}>
                    <div
                      className='flex w-100 flex-center'
                      // style={{ border: 'solid' }}
                    >
                      <label htmlFor={`question${index}`}>Question</label>
                      <input
                        type='text'
                        style={{ height: '35px' }}
                        id={`question${index}`}
                        name='question'
                        value={qna.question}
                        placeholder='Type Something...'
                        onChange={(event) => handleQnAInputChange(index, event)}
                      />
                    </div>
                    <div className='flex w-100'>
                      <label htmlFor={`answer${index}`}>Answer</label>
                      <textarea
                        id={`answer${index}`}
                        name='answer'
                        rows={4}
                        cols={50}
                        placeholder='Type Something...'
                        value={qna.answer}
                        onChange={(event) => handleQnAInputChange(index, event)}
                      />
                    </div>
                    <div className='flex w-100'>
                      <span
                        className='remove-btn'
                        onClick={() => removeQna(index)}
                      >
                        <DeleteForeverRoundedIcon />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <br />
            <div className='add-more-btn flex' onClick={addQna}>
              <AddIcon /> Add more
            </div>
          </div>

          {/* capital information section */}
          <div className='capital-information-section'>
            <span className='admin-strategy-heading'>Capital Information</span>
            <div className='horizontal-line'></div>
            <div className='capital-info-part'>
              <div className='capital-info-min-cap'>
                <span className='admin-strategy-heading'>Minimum Capital</span>
                <div className='admin-strategy-info'>
                  <span className='minimum-capital-input'>
                    <CurrencyRupeeIcon />{' '}
                    <input
                      type='number'
                      name='minimum_investment_capital'
                      id=''
                      onChange={handleChange}
                    />
                  </span>
                  <p className='dummy-text'>
                    (Note: Lorem ipsum dolor sit amet Lorem ipsum dolor sit
                    ametLorem ipsum dolor sit ametlorem ipsum dolor sit)
                  </p>
                </div>{' '}
              </div>

              {/* 
               <div className='capital-info-min-cap'>
                <span className='admin-strategy-heading'>CAGR %</span>
                <div className='admin-strategy-info'>
                  <span className='minimum-capital-input'>
                    <input
                      type='number'
                      name='cagr_pct'
                      id=''
                      onChange={handleChange}
                    />
                  </span>
                  <span className='dummy-text'>
                    (Note: Lorem ipsum dolor sit amet Lorem ipsum dolor sit
                    ametLorem ipsum dolor sit ametlorem ipsum dolor sit)
                  </span>
                </div>{' '}
              </div>
               */}

              {/* risk section */}
              <div className='capital-info-min-cap  admin-content-risk-section'>
                <span className='admin-strategy-heading'>Risk</span>
                <div className='admin-risk-content'>
                  <span className='risk-options'>
                    <div
                      className={`risk-option ${
                        formData.risk === 'Low Risk' ? 'selected' : ''
                      }`}
                      onClick={() => handleRiskSelection('Low Risk')}
                    >
                      <svg
                        className='low'
                        xmlns='http://www.w3.org/2000/svg'
                        width='8'
                        height='15'
                        viewBox='0 0 8 15'
                        fill='none'
                      >
                        <path
                          d='M5 11.2825L5 0.610352L3 0.610352L3 11.2825L0 11.2825L4 14.828L8 11.2825H5Z'
                          fill='#2ACD1C'
                        />
                      </svg>
                      Low
                    </div>
                    <div
                      className={`risk-option ${
                        formData.risk === 'Medium Risk' ? 'selected' : ''
                      }`}
                      onClick={() => handleRiskSelection('Medium Risk')}
                    >
                      <svg
                        className='medium'
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='17'
                        viewBox='0 0 14 17'
                        fill='none'
                      >
                        <path
                          d='M11 13.171V6.94191H9V13.171H6L10 16.7165L14 13.171H11ZM4 0.72168L0 4.26721H3V10.4963H5V4.26721H8L4 0.72168Z'
                          fill='#E7AF1D'
                        />
                      </svg>
                      Medium
                    </div>
                    <div
                      className={`risk-option ${
                        formData.risk === 'High Risk' ? 'selected' : ''
                      }`}
                      onClick={() => handleRiskSelection('High Risk')}
                    >
                      <svg
                        className='high'
                        xmlns='http://www.w3.org/2000/svg'
                        width='8'
                        height='15'
                        viewBox='0 0 8 15'
                        fill='none'
                      >
                        <path
                          d='M5 4.04467L5 14.7168H3L3 4.04467L0 4.04467L4 0.499138L8 4.04467H5Z'
                          fill='#F82929'
                        />
                      </svg>
                      High
                    </div>
                  </span>
                  <span className='dummy-text'>
                    (Note: Lorem ipsum dolor sit amet Lorem ipsum dolor sit
                    ametLorem ipsum dolor sit ametLorem ipsum dolor sit)
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* insight info section */}
          <div className='insights-information-section'>
            <span className='admin-strategy-heading'>Insights Information</span>
            <div className='horizontal-line'></div>
            <div className='insights-information-content'>
              <div className='blog-post-section'>
                <div className='icon-section'>
                  {!blogPdfpreview && (
                    <>
                      <label
                        htmlFor='blogPdfInput'
                        className='choose-icon-button'
                      >
                        <FileUploadIcon /> Upload File
                      </label>
                      <input
                        type='file'
                        id='blogPdfInput'
                        style={{ display: 'none' }}
                        onChange={handleBlogPdfChange}
                        // accept='image/*'
                        accept='.pdf'
                        // required
                      />
                    </>
                  )}

                  {blogPdfpreview && (
                    <div
                      className='blog-image-section'
                      style={{ width: '200px', height: '100px' }}
                    >
                      {/* <img
                        className='blog-selected-image'
                        src={blogPdfpreview}
                        alt='Selected Imagez'
                      /> */}
                      {/* <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                          <Viewer
                            fileUrl={URL.createObjectURL(blogPdfpreview)}
                            plugins={[newPlugin]}
                          />
                      </Worker> */}
                      <label htmlFor='blogPdfInput' className='edit-blog-image'>
                        <EditIcon
                          style={{
                            height: '15px',
                            width: '15px',
                            padding: '2px',
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            backgroundColor: '#fff',
                            border: 'solid',
                            borderRadius: '50%',
                          }}
                        />
                        <input
                          type='file'
                          id='blogPdfInput'
                          style={{ display: 'none' }}
                          onChange={handleBlogPdfChange}
                          // accept='image/*'
                          accept='.pdf'
                          // required
                        />
                      </label>
                    </div>
                  )}
                </div>

                <div className='blog-info'>
                  <span className='admin-strategy-heading'>Blog Post</span>
                  <br />
                  <input
                    type='text'
                    placeholder='Your description'
                    value={blogSectionText}
                    onChange={handleBlogTextChange}
                    maxLength={70}
                    required
                  />
                  <br />
                  <span style={{ display: 'flex', fontSize: '10px' }}>
                    (Max 70 Characters Allowed)
                  </span>

                  {/* <div className='button-row'>
                    <button onClick={handleUploadClick}>
                      {' '}
                      <FileUploadIcon /> Upload
                    </button>
                    <button
                      style={{ width: '72.5%' }}
                      onClick={handleAddLinkClick}
                    >
                      <LinkIcon />
                      Add Link
                    </button>
                  </div> */}
                </div>
              </div>
              {/*  */}
              <div className='blog-post-section'>
                <div className='icon-section'>
                  {!methodologyPdfPreview && (
                    <>
                      <label
                        htmlFor='methodologyInputPdf'
                        className='choose-icon-button'
                      >
                        <FileUploadIcon /> Upload File
                      </label>
                      <input
                        type='file'
                        id='methodologyInputPdf'
                        style={{ display: 'none' }}
                        onChange={handleMethodologyPdfChange}
                        // accept='image/*'
                        accept='.pdf'
                        // required
                      />
                    </>
                  )}

                  {methodologyPdfPreview && (
                    <div className='blog-image-section'>
                      <img
                        className='blog-selected-image'
                        src={methodologyPdfPreview}
                        alt='Selected Imagez'
                      />
                      <label
                        htmlFor='methodologyInputPdf'
                        className='edit-blog-image'
                      >
                        <EditIcon
                          style={{
                            height: '15px',
                            width: '15px',
                            padding: '2px',
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            backgroundColor: '#fff',
                            border: 'solid',
                            borderRadius: '50%',
                          }}
                        />
                        <input
                          type='file'
                          id='methodologyInputPdf'
                          style={{ display: 'none' }}
                          onChange={handleMethodologyPdfChange}
                          // accept='image/*'
                          accept='.pdf'
                          // required
                        />
                      </label>
                    </div>
                  )}
                </div>

                <div className='blog-info'>
                  <span className='admin-strategy-heading'>Methodology</span>
                  <br />
                  <input
                    type='text'
                    placeholder='Your description'
                    value={methodologySectionText}
                    onChange={handleMethodologyTextChange}
                    required
                    maxLength={70}
                  />
                  <br />
                  <span style={{ fontSize: '10px', display: 'flex' }}>
                    (Max 70 Characters Allowed)
                  </span>

                  {/* <div className='button-row'>
                    <button onClick={handleUploadClick}>
                      {' '}
                      <FileUploadIcon /> Upload
                    </button>
                    <button
                      style={{ width: '72.5%' }}
                      onClick={handleAddLinkClick}
                    >
                      <LinkIcon />
                      Add Link
                    </button>
                  </div> */}
                </div>
              </div>

              {/*  */}

              <div className='blog-post-section'>
                <div className='icon-section'>
                  {!factSheetPdfPreview && (
                    <>
                      <label
                        htmlFor='factSheetInputPdf'
                        className='choose-icon-button'
                      >
                        <FileUploadIcon /> Upload File
                      </label>
                      <input
                        type='file'
                        id='factSheetInputPdf'
                        style={{ display: 'none' }}
                        onChange={handleFactsheetPdfChange}
                        // accept='image/*'
                        accept='.pdf'

                        // required
                      />
                    </>
                  )}

                  {factSheetPdfPreview && (
                    <div className='blog-image-section'>
                      <img
                        className='blog-selected-image'
                        src={factSheetPdfPreview}
                        alt='Selected Imagez'
                      />
                      <label
                        htmlFor='factSheetInputPdfImg'
                        className='edit-blog-image'
                      >
                        <EditIcon
                          style={{
                            height: '15px',
                            width: '15px',
                            padding: '2px',
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            backgroundColor: '#fff',
                            border: 'solid',
                            borderRadius: '50%',
                          }}
                        />
                        <input
                          type='file'
                          id='factSheetInputPdfImg'
                          style={{ display: 'none' }}
                          onChange={handleFactsheetPdfChange}
                          // accept='image/*'
                          accept='.pdf'
                          // required
                        />
                      </label>
                    </div>
                  )}
                </div>

                <div className='blog-info'>
                  <span className='admin-strategy-heading'>Factsheets</span>
                  <br />
                  <input
                    type='text'
                    placeholder='Your description'
                    value={factSheetSectionText}
                    onChange={handleFactSheetTextChange}
                    maxLength={70}
                    required
                  />
                  <br />
                  <span style={{ display: 'flex', fontSize: '10px' }}>
                    (Max 70 Characters Allowed)
                  </span>

                  {/* <div className='button-row'>
                    <button onClick={handleUploadClick}>
                      {' '}
                      <FileUploadIcon /> Upload
                    </button>
                    <button
                      style={{ width: '72.5%' }}
                      onClick={handleAddLinkClick}
                    >
                      <LinkIcon />
                      Add Link
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className='admin-create-strategy-btn' type='submit'>
          Create Strategy
        </button>
      </form>
    </div>
  );
};

export default AdminCreateContent;
