import React, { useRef } from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Theme } from '../../Utils/Constants';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {
  ArticleOutlined,
  DescriptionOutlined,
  SubscriptionsOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface HeaderDropDownProps {
  onSignOut: () => void;
}

const HeaderDropDown: React.FC<HeaderDropDownProps> = ({ onSignOut }) => {
  const containerRef = useRef(null);
  const navigate = useNavigate();

  function handleSignOut() {
    onSignOut();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }

  return (
    <div
      style={styles.dropdownStyle}
      ref={containerRef as React.RefObject<HTMLDivElement>}
    >
      <div
        style={styles.dropDownItem}
        onClick={() => {
          navigate('/admin', { replace: true });
        }}
      >
        <PersonOutlineIcon style={styles.icon} />
        <span>Dashboard</span>
      </div>
      {/* <div style={styles.dropDownItem}>
        <SubscriptionsOutlined style={styles.icon} />
        <span>My Subscription</span>
      </div>
      <div style={styles.dropDownItem}>
        <DescriptionOutlined style={styles.icon} />
        <span>Broker</span>
      </div>
      <div style={styles.dropDownItem}>
        <OfflineBoltOutlinedIcon style={styles.icon} />
        <span>Plan & Pricing</span>
      </div> */}
      <div
        style={styles.dropDownItem}
        onClick={() => {
          navigate('/admin-create-strategy', { replace: true });
        }}
      >
        <TimelineOutlinedIcon style={styles.icon} />
        <span>Create Strategy</span>
      </div>
      {/* <div style={styles.dropDownItem}>
        <ArticleOutlined style={styles.icon} />
        <span>Help & Support</span>
      </div> */}
      <div style={styles.dropDownItem} onClick={handleSignOut}>
        <PowerSettingsNewIcon style={styles.icon} />
        <span>Logout</span>
      </div>
    </div>
  );
};

const styles = {
  icon: {
    marginRight: Theme.gapSmall,
  },
  dropDownItem: {
    display: 'flex',
    cursor: 'pointer',
    fontSize: Theme.fontSizes.h5,
    paddingTop: Theme.gapTiny,
  },
  dropdownStyle: {
    position: 'absolute' as 'absolute',
    zIndex: 99999,
    top: '64px',
    right: '20px',
    backgroundColor: '#fff',
    color: '#000',
    padding: Theme.gapTiny,
    borderRadius: '5px',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
  },
};

export default HeaderDropDown;
